import React, { Component } from "react";
import ReadOffer from "../components/accounts/ReadOffer";
import RegisterForm from "../components/accounts/RegisterForm";
import { connect } from "react-redux";

import { Layout, Row, Col } from "antd";
import glsimage from "../assets/glsimage.png";

const { Content } = Layout;

class Register extends Component {
  state = {
    offerId: "",
  };
  componentDidMount() {
    this.parseQueryParams();
  }
  componentDidUpdate() {
    this.parseQueryParams();
  }

  parseQueryParams() {
    const query = new URLSearchParams(this.props.location.search);
    for (let param of query.entries()) {
      if (param[0] === "offer_id") {
        if (this.state.offerId === "" || this.state.offerId !== param[1]) {
          this.setState({ offerId: param[1] });
        }
      }
    }
  }
  render() {
    return (
      <Content className="landing-sub landing-margin">
        <Row justify="space-between">
          <Col
            xs={0}
            sm={0}
            md={12}
            lg={12}
            xl={14}
            style={{ margin: "0 auto" }}
            className="mobile-tablet-show"
          >
            <div className="heading-desktop">
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "44px",
                  textShadow: "1px 1px 1px #FF0000",
                }}
              >
                Structured settlement <br /> funding made simple
              </div>
              <p>Trust us to get you more.</p>
            </div>
            <div className="heading-desktop">
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "40px",
                  marginTop: "20px",
                }}
              >
                Reach Financial Freedom
              </div>
              <p style={{ fontSize: "18px" }}>
                Gold Leaf Settlement Soultions is redefining the process of
                transferring future assets with our new, easy-to-use online
                platform created to relieve our customers of the headaches that
                often come with structured settlement transactions. We have
                helped thousands of clients nationwide reach financial freedom.
                Give us the opportunity to do the same for you.
              </p>
              <p style={{ fontSize: "18px" }}>
                Contact our live support team at (844) 989-3171 for questions
                regarding our new digital platform.
              </p>
            </div>
          </Col>
          <Col className="form-container">
            {!this.props.validOfferId ? (
              <ReadOffer
                key={this.state.offerId}
                offerId={this.state.offerId}
              />
            ) : (
              <RegisterForm />
            )}
          </Col>
        </Row>
        <Row>
          <Col
            xs={0}
            sm={0}
            md={24}
            lg={24}
            xl={24}
            className="mobile-tablet-show"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              // className="only-mobile"
              style={{
                width: "100%",
                maxWidth: "700px",
                marginBottom: "20px",
              }}
            >
              <img src={glsimage} alt="proba" width="100%" height="auto" />
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerForm: state.form.offerForm,
    validOfferId: state.register.offerId,
  };
};

export default connect(mapStateToProps)(Register);
