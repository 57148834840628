import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { ReactComponent as MainLogo } from "../../../assets/gls_yellow_logo.svg";

import { AiOutlineQuestionCircle } from "react-icons/ai";

function DesktopLinks() {
  let history = useHistory();
  const handleRedirect = () => {
    history.push("/contact");
  };
  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <Row gutter={4} justify="space-between">
      <Col>
        <Link to={"/"}>
          <MainLogo width="160" height="35.49" className="gls-nav-logo" />
        </Link>
      </Col>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "unset",
            position: "relative",
          }}
        >
          <AiOutlineQuestionCircle
            onClick={handleRedirect}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            style={{
              fontSize: "21px",
              // paddingTop: "0px",
              marginBottom: "3px",
              marginRight: "20px",
              cursor: "pointer",
            }}
          />
          {showTooltip && (
            <div className="tooltip">
              <div>Support</div>
            </div>
          )}
        </div>
        <div>
          <span style={{ fontSize: "15px" }}>(844) 989-3171</span>
        </div>
      </div>
    </Row>
  );
}

export default DesktopLinks;
