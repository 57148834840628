export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADING = "USER_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_ME = "UPDATE_ME";
export const USERS_ME = "USERS_ME";

export const MENU_OPENED = "MENU_OPENED";
export const MENU_CLOSED = "MENU_CLOSED";
export const SCREEN_IS_DESKTOP = "SCREEN_IS_DESKTOP";
export const SCREEN_IS_MOBILE = "SCREEN_IS_MOBILE";
export const MENU_ITEM_SELECTED = "MENU_ITEM_SELECTED";

export const OPEN_OFFER_FORM = "OPEN_OFFER_FORM";
export const CUSTOMER_SUPPORT_REQUEST = "CUSTOMER_SUPPORT_REQUEST";
export const CUSTOMER_SUPPORT_REQUEST_START = "CUSTOMER_SUPPORT_REQUEST_START";
export const REQUEST_OFFER = "REQUEST_OFFER";
export const FORM_LOADING = "FORM_LOADING";
export const REQUEST_OFFER_ERROR = "REQUEST_OFFER_ERROR";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const FORM_RESET = "FORM_RESET";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAIL = "RECOVER_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const GET_OFFERS = "GET_OFFERS";
export const GET_OFFER = "GET_OFFER";
export const GET_OFFER_ERROR = "GET_OFFER_ERROR";
export const CLEAR_OFFER_ERROR = "CLEAR_OFFER_ERROR";
export const OFFERS_LOADING = "OFFERS_LOADING";
export const OFFER_ACCEPTED = "OFFER_ACCEPTED";
export const OFFER_DENIED = "OFFER_DENIED";

export const PENDING_OFFER_ACCEPTING = "PENDING_OFFER_ACCEPTING";
export const PENDING_OFFER_ACCEPTED = "PENDING_OFFER_ACCEPTED";
export const RESET_PENDING_OFFER_ACCEPTED = "RESET_PENDING_OFFER_ACCEPTED";

export const CHECK_OFFER_ID = "CHECK_OFFER_ID";
export const ACCEPTED_OFFER_ID = "ACCEPTED_OFFER_ID";
export const REJECTED_OFFER_ID = "REJECTED_OFFER_ID";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_START = "REGISTER_START";
export const TEST_TOKEN_SUCCESS = "TEST_TOKEN_SUCCESS";
export const TEST_TOKEN_FAIL = "TEST_TOKEN_FAIL";

export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_LOADING = "UPLOAD_DOCUMENT_LOADING";
export const DELETE_FILE = "DELETE_FILE";

export const NOTIFICATION_READ = "NOTIFICATION_READ";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";

export const REQUEST_CASH = "REQUEST_CASH";
