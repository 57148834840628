import React from "react";
import { useHistory } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import DashboardCardHOC from "../../components/dashboard/DashboardCardHOC";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
// import { readUserMe } from "../../store/actions/auth";

const MyDashboard = (props) => {
  let history = useHistory();
  const offers = useSelector((state) => state.offers.offers);
  const pendingOffers = offers.filter(
    (offer) => offer.Status__c === "Offer Out"
  );
  // const dispatch = useDispatch();
  // const token = useSelector((state) => state.auth.token);
  // React.useEffect(() => {
  //   dispatch(readUserMe(token));
  // }, [dispatch, token]);

  const redirectToContactForm = () => {
    history.push("/contact");
  };
  let pendingOffersReminder = null;
  if (pendingOffers.length !== 0) {
    pendingOffersReminder = (
      <div
        className="pending-deals-section only-mobile"
        style={{ backgroundColor: "#b7ff96" }}
      >
        <InfoCircleOutlined className="deals-info-icon" />
        <Link to={"/deals/pending"}>
          You have new deals pending approval. &nbsp;
          <span className="deals-info-click">Click Here</span>
        </Link>
      </div>
    );
  }

  return (
    <>
      <DashboardHeader title="My Dashboard" />
      <div className="page-content">
        {pendingOffersReminder}
        <p className="dashboard-welcome">Welcome to Your GOLDLEAF dashboard.</p>
        <p className="dashboard-text">
          Gold Leaf is committed to you. After years of hearing people complain
          about getting bad deals and being harassed by sales people we set out
          to make this experience stress free.
        </p>
        <p className="dashboard-text">
          We guarantee you our best rate possible right off the bat,we will not
          try to take advantage of you with a low offer like many other
          companies. This way you don't have to worry about negotiations and can
          decide yourself whether the offer is in your best interest.
        </p>
        <p className="dashboard-text">
          Our unique Customer Dashboard keeps you engaged throughout the whole
          process so you are always informed.
        </p>
        <p className="dashboard-text">
          You can{" "}
          <span
            onClick={redirectToContactForm}
            style={{
              color: "#1085b5",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            contact our support
          </span>{" "}
          for more information.
        </p>
      </div>
    </>
  );
};

export default DashboardCardHOC(MyDashboard);
