import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { BsCameraFill } from "react-icons/bs";
import { Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { uploadDocuments } from "../../../../../store/actions/uploads";
import Spinner from "../../../../../components/UI/Spinner";
import AlreadyUploaded from "./AlreadyUploaded";
import WebCam from "../../../../../components/UI/Modal/WebCam";
import Modal from "../../../../../components/UI/Modal/Modal";

const DealDetailsUploads = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const uploadingDocuments = useSelector(
    (state) => state.uploads.uploadingDocuments
  );
  const uploadedDocuments = useSelector(
    (state) => state.uploads.uploadedDocuments
  );

  const [selectedFile, setSelectedFile] = React.useState({});
  const [hasWebCam, setHasWebCam] = React.useState();
  const [takingPhoto, setTakingPhoto] = React.useState(false);
  const [videoStreamParent, setVideoStreamParent] = React.useState(null);
  const fileInput = React.useRef([]);

  const handleClick = (doc) => {
    console.log(doc);
    fileInput.current[doc].click();
    console.log(fileInput);
  };
  const detectWebcam = () => {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return false;
    md.enumerateDevices().then((devices) => {
      console.log(devices);
      setHasWebCam(devices.some((device) => "videoinput" === device.kind));
    });
  };

  const fileSelectHandler = (doc, event) => {
    if (event.target) {
      console.log("event target", event.target.files[0]);
      console.dir(event.target.files[0]);
      if (!event.target.files[0]) {
        return;
      }
      console.log(doc, "doc");
      let prevState = selectedFile;
      setSelectedFile({
        ...prevState,
        [doc]: event.target.files[0],
      });
      dispatch(
        uploadDocuments(token, event.target.files[0], doc, props.offer.Id)
      );
    }
  };

  const closeModalHandler = (notAllowed = false) => {
    setTakingPhoto(false);
    if (!notAllowed) {
      videoStreamParent.getTracks().forEach((track) => {
        if (track.kind === "video") {
          track.stop();
          setVideoStreamParent(null);
        }
      });
    }
  };

  React.useEffect(() => {
    detectWebcam();
  }, []);

  let status;
  let requiredDocuments = null;
  const toDisplay = [];
  const uploaded = [];

  if (props.offer) {
    for (let key in props.offer) {
      if (key.startsWith("Document_Requested") && props.offer[key] !== null) {
        toDisplay.push(props.offer[key]);
      }
    }
    toDisplay.push("Other relevant documents");
    requiredDocuments = toDisplay.map((doc) => {
      let listOfUploadedDocuments = null;
      let divright = (
        <>
          {window.screen.width > 1020 && hasWebCam ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <BsCameraFill
                style={{ fontSize: "23px", cursor: "pointer" }}
                onClick={() => setTakingPhoto(doc)}
              />
            </div>
          ) : null}
          <div
            onClick={handleClick.bind(this, doc)}
            className="deal-details-upload"
          >
            <UploadOutlined />
            <span
              className="not-visible-on-mobile"
              style={{ marginLeft: "10px" }}
            >
              Upload
            </span>
          </div>
        </>
      );
      if (props.offer.uploaded_documents) {
        if (uploadingDocuments.indexOf(doc) !== -1) {
          divright = (
            <div>
              <Spinner />
            </div>
          );
        }
        if (
          uploadedDocuments.some(
            (upload) =>
              upload.document_type === doc && doc !== "Other relevant documents"
          )
        ) {
          // divright = <div className="accept-label">uploaded</div>;
          uploaded.push(doc);
        }
        if (uploadedDocuments.some((upload) => upload.document_type === doc)) {
          listOfUploadedDocuments = (
            <AlreadyUploaded
              uploadedDocuments={uploadedDocuments.filter(
                (upload) => upload.document_type === doc
              )}
            />
          );
        }
      }
      return (
        <div key={doc} className="deal-details-row">
          <div
            className="flex-row-always spread"
            style={{ paddingBottom: "10px" }}
          >
            <div className="document-type">
              {doc}
              {doc !== "Other relevant documents" ? (
                <span style={{ marginLeft: "10px" }}>(required)</span>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                onChange={fileSelectHandler.bind(this, doc)}
                ref={(el) => (fileInput.current[doc] = el)}
              />
              {divright}
            </div>
          </div>
          {listOfUploadedDocuments}
        </div>
      );
    });
    status = (
      <div
        style={{
          paddingBottom: "20px",
        }}
      >
        <div
          className="flex-row"
          style={{
            paddingTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{ width: "200px", maxWidth: "100%", alignSelf: "center" }}
          >
            Required Documents uploaded: {uploaded.length} out of{" "}
            {toDisplay.length - 1}
          </div>
          <Progress
            type="line"
            showInfo={false}
            percent={(uploaded.length / (toDisplay.length - 1)) * 100}
            style={{ width: "80%", margin: "0 20px", boxSizing: "border-box" }}
          />
        </div>
      </div>
    );
  } else status = null;
  return (
    <>
      {status}
      {requiredDocuments}
      {takingPhoto && (
        <Modal
          show={takingPhoto}
          closeModal={closeModalHandler}
          transparent
          noPadding
          noBorder
        >
          <WebCam
            documentType={takingPhoto}
            offerId={props.offer.Id}
            setVideoStream={setVideoStreamParent}
            closeModal={closeModalHandler}
          />
        </Modal>
      )}
    </>
  );
};

export default DealDetailsUploads;
