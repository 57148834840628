import React from "react";
import { Link } from "react-router-dom";
import { faqs } from "../../components/menu/data/faqs";
import MenuCardHOC from "../../components/menu/MenuCardHOC";

import { Row, Col } from "antd";

function FAQ() {
  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <span
          style={{ textDecoration: "underline" }}
          className="dashboard-title"
        >
          FAQ
        </span>
      </div>
      <Row className="menu-btm-row">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row className="content-card">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {faqs.map(({ id, question, answer }) => (
                <div key={id}>
                  <h3>
                    <strong>{question}</strong>
                  </h3>
                  <p>{answer}</p>
                </div>
              ))}
              <div className="menu-card-links">
                <div className="menu-card-link-container">
                  <Link to="/contact">
                    Want more information? Call or Contact us
                  </Link>
                </div>
                <div className="menu-card-link-container">
                  <Link to="/about">Want to learn more about us?</Link>
                </div>
                <div className="menu-card-link-container">
                  <Link to="/why-choose-us">How does it all work?</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default MenuCardHOC(FAQ);
