import moment from "moment";
import "moment-timezone";

export const BASE_URL = "https://api.glsfunding.com/";

export const OFFER_STATUSES = [
  "Offer Out",
  "Offer Accepted",
  "Disclosure Out",
  "Contract Sent",
  "Contract Back",
  "Sent to Counsel",
  "Court Date Set",
  "Court Approved",
  "Approved for Funding",
  "Funded",
  "Dead",
];

const timezones = [...moment.tz.names()];

export const TIME_ZONES = [];
timezones.forEach((timezone) => {
  let abbr = moment.tz(timezone).format("z");
  let utc_offset = moment.tz(timezone).utcOffset();
  TIME_ZONES.push({ abbreviation: abbr, offset: utc_offset, zone: timezone });
});
