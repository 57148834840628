import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Switch } from "antd";
import { Button } from "antd";
import moment from "moment";
import { Select } from "antd";

import DashboardCardHOC from "../../components/dashboard/DashboardCardHOC";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import { updateMe } from "../../store/actions/auth";
import { TIME_ZONES } from "../../constants/constants";

const { Option } = Select;

const Profile = (props) => {
  const existingTimezone = useSelector((state) => state.auth.timezone);
  const {
    userFullName,
    userPhoneNumber,
    userEmail,
    sendNotificationsViaEmail,
    subscribeToNewsletter,
    dateOfBirth,
  } = useSelector((state) => state.auth);
  const [editing, setEditing] = React.useState(false);
  const [newTimezone, setNewTimezone] = React.useState(existingTimezone);
  const displayDateOfBirth = moment(dateOfBirth, "YYYY-MM-DD").format(
    "MM/DD/YYYY"
  );
  const [name, setName] = React.useState(userFullName);
  const [phone, setPhone] = React.useState(userPhoneNumber);
  // const [address, setAddress] = React.useState(userAddress);
  const changeEditing = () => {
    setEditing(!editing);
  };

  React.useEffect(() => {
    setEditing(false);
  }, [userFullName, userPhoneNumber, existingTimezone]);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const handleEmailChange = () => {
    dispatch(
      updateMe(
        { send_notifications_via_email: !sendNotificationsViaEmail },
        token
      )
    );
  };
  const handleTimeZoneChange = (value) => {
    setNewTimezone(value);
    console.log(value);
  };
  const handleSubscriptionChange = () => {
    dispatch(
      updateMe({ subscribe_to_newsletter: !subscribeToNewsletter }, token)
    );
  };
  const updateProfileInfoHandler = () => {
    dispatch(
      updateMe({ full_name: name, phone: phone, timezone: newTimezone }, token)
    );
  };
  const cancelUpdateProfileInfo = () => {
    setEditing(false);
    setName(userFullName);
    setPhone(userPhoneNumber);
  };

  const onChange = (event) => {
    if (event.target.name === "name") {
      setName(event.target.value);
    } else if (event.target.name === "phone") {
      setPhone(event.target.value);
    }
  };

  const displayZone = (zoneName) => {
    if (!zoneName) {
      return "";
    }
    return zoneName.split("/").join(" / ").replaceAll("_", " ");
  };

  return (
    <>
      <DashboardHeader title="Profile" />
      <div className="page-content">
        <div className="profile-property-row">
          <div className="profile-property">Name:</div>
          <div>
            {editing ? (
              <Input
                autoComplete="off"
                name="name"
                style={{ width: "100%" }}
                onChange={onChange}
                size="large"
                value={name}
              />
            ) : (
              userFullName
            )}
          </div>
        </div>
        <div className="profile-property-row">
          <div className="profile-property">Date of birth:</div>
          <div className="profile-property">{displayDateOfBirth}</div>
        </div>
        <div className="profile-property-row">
          <div className="profile-property">Phone:</div>
          <div>
            {editing ? (
              <Input
                autoComplete="off"
                name="phone"
                style={{ width: "100%" }}
                onChange={onChange}
                size="large"
                value={phone}
              />
            ) : (
              userPhoneNumber
            )}
          </div>
        </div>
        <div className="profile-property-row">
          <div className="profile-property">Email:</div>
          <div>{userEmail}</div>
        </div>
        <div className="profile-property-row">
          <div className="profile-property">Time zone:</div>
          <div style={{ flex: 1 }}>
            {editing ? (
              <Select
                showSearch
                placeholder="Select a timezone"
                defaultValue={existingTimezone}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleTimeZoneChange}
                style={{
                  width: "400px",
                  maxWidth: "80%",
                }}
              >
                {TIME_ZONES.map((timezone) => {
                  let UTCstring;
                  let positivity = "";
                  if (timezone.offset === 0) {
                    UTCstring = "UTC";
                  } else {
                    if (timezone.offset > 0) {
                      positivity = "+";
                    } else {
                      positivity = "";
                    }
                    if (timezone.offset % 60 === 0) {
                      UTCstring =
                        "(UTC " + positivity + timezone.offset / 60 + ":00)";
                    } else {
                      UTCstring =
                        "(UTC " +
                        positivity +
                        Math.floor(timezone.offset / 60) +
                        ":" +
                        (timezone.offset % 60) +
                        ")";
                    }
                  }
                  return (
                    <Option key={timezone.zone} value={timezone.zone}>
                      {displayZone(timezone.zone) +
                        ", " +
                        UTCstring +
                        ", " +
                        timezone.abbreviation}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              displayZone(existingTimezone)
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "50px",
            marginLeft: "100px",
            display: "flex",
          }}
        >
          {!editing ? (
            <Button
              onClick={changeEditing}
              className="button"
              style={{ backgroundColor: "#f4f4f4" }}
            >
              Edit Profile info
            </Button>
          ) : (
            <>
              <Button
                onClick={updateProfileInfoHandler}
                style={{ marginRight: "20px", backgroundColor: "#f4f4f4" }}
              >
                Save Changes
              </Button>
              <Button
                onClick={cancelUpdateProfileInfo}
                style={{ backgroundColor: "#f4f4f4" }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
        <div className="profile-checkboxes">
          <div className="flex-row">
            <div className="profile-property-checkbox">
              Send deal notifications via email
            </div>
            <Switch
              checked={sendNotificationsViaEmail}
              onChange={handleEmailChange}
            ></Switch>
          </div>
          <div className="flex-row" style={{ paddingTop: "10px" }}>
            <div className="profile-property-checkbox">
              Subscribe to newsletter
            </div>
            <Switch
              checked={subscribeToNewsletter}
              onChange={handleSubscriptionChange}
            ></Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCardHOC(Profile);
