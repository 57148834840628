export const faqs = [
  {
    id: "faq-1",
    question: "How long does the process take?",
    answer:
      "It depends on your local court and how quick they assign a court date for your hearing. Typically a transfer takes about 3-6 weeks from the date of signing.",
  },
  {
    id: "faq-2",
    question: "Do I need to go to court?",
    answer:
      "Most jurisdictions do require you to attend a hearing to complete your transfer. If you are not able to attend please reach out to a support team member to discuss alternatives.",
  },
  {
    id: "faq-3",
    question: "Is my information safe?",
    answer:
      "Yes, we used a secure encrypted server to keep all of your information safe. When possible we will also redirect as much information as possible from your court case so other companies will not contact you",
  },
];
