import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import About from "../../pages/menu/AboutGLS";
import HowItWorks from "../../pages/menu/HowItWorks";
import WhyChooseUs from "../../pages/menu/WhyChooseUs";
import FAQ from "../../pages/menu/FAQ";
import Contact from "../../pages/menu/Contact";
import { connect } from "react-redux";
import { Layout } from "antd";

const { Content } = Layout;

function MenuCardContainer(props) {
  return (
    <Content className="content-sub" style={{ paddingTop: "36px" }}>
      <Switch>
        <Route exact path="/about" component={About} />
        <Route exact path="/how-it-works" component={HowItWorks} />
        <Route exact path="/why-choose-us" component={WhyChooseUs} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/contact" component={Contact} />
        <Redirect to={props.isAuth ? "/mydashboard" : "/"} />
      </Switch>
    </Content>
  );
}

const mapStateToProps = (state) => {
  return {
    menuItem: state.menu.menuItem,
    isAuth: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(MenuCardContainer);
