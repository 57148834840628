import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// Ant Design icons prevent input areas backgroundColor changes

import { ReactComponent as MobileLogo } from "../assets/gls_mobile_logo.svg";
import { login } from "../store/actions/auth";
import Modal from "../components/UI/Modal/Modal";
import RequestOffer from "../components/UI/Modal/RequestOffer";
import { FORM_RESET } from "../store/actions/types";

export class Login extends Component {
  state = {
    email: "",
    password: "",
    rememberUser: false,
    requestOffer: false,
  };

  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    this.props.onLogin(
      this.state.email,
      this.state.password,
      this.state.rememberUser
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCheckBoxChange = (e) => {
    this.setState({ rememberUser: e.target.checked });
  };

  openReadOfferForm = () => {
    this.props.openReadOfferForm();
  };

  requestOfferHandler = () => {
    this.setState({ requestOffer: true });
  };

  closeRequestOffer = () => {
    this.setState({ requestOffer: false });
    this.props.resetRequestOfferForm();
  };

  render() {
    const { email, password } = this.state;
    const styled_p = {
      fontSize: "16px",
      color: "#D9D9D9",
    };
    return (
      <motion.div
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn" }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
      >
        <Form
          layout="vertical"
          className="form login-form form-outer"
          onFinish={this.onSubmit}
        >
          <div className="form-inner">
            <MobileLogo className="form-logo" />
            <Input
              name="email"
              onChange={this.onChange}
              style={{ backgroundColor: "#2e2e2e" }}
              className="input"
              value={email}
              size="large"
              placeholder="Email"
              prefix={<UserOutlined style={{ color: "#ffd700" }} />}
            />
            <br />
            <Input
              name="password"
              type="password"
              onChange={this.onChange}
              style={{ backgroundColor: "#2e2e2e" }}
              className="input"
              value={password}
              size="large"
              placeholder="Password"
              prefix={<LockOutlined style={{ color: "#ffd700" }} />}
            />
            <div style={{ color: "red" }}>{this.props.authErrorMessage}</div>
            <div className="form-options">
              <Checkbox onChange={this.onCheckBoxChange}>Remember Me</Checkbox>
              <Link to="/recover">Forgot Password</Link>
            </div>
            <Button type="primary" htmlType="submit" size="large">
              Log In
            </Button>
            <p style={{ ...styled_p, paddingTop: "20px" }}>
              New to Gold Leaf?
              <br />
              <Link to={"/register"}>Register Here</Link> with an existing offer
            </p>
            <p style={styled_p}>
              Don't have an offer?
              <br />
              <span
                onClick={this.requestOfferHandler}
                style={{ color: "#ffd700" }}
              >
                Request one here
              </span>
            </p>
          </div>
        </Form>
        {this.state.requestOffer ? (
          <Modal
            show={this.state.requestOffer}
            closeModal={this.closeRequestOffer}
            noBorder
            backgroundColor="#f0f0f0"
            width="600px"
            maxwidth="90%"
            overflowY="auto"
          >
            <RequestOffer close={this.closeRequestOffer} />
          </Modal>
        ) : null}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authErrorMessage: state.auth.authErrorMessage,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password, rememberUser) =>
      dispatch(login(email, password, rememberUser)),
    // openReadOfferForm: () => dispatch()
    resetRequestOfferForm: () => dispatch({ type: FORM_RESET }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
