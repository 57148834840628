import React from "react";
import { useRouteMatch } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";

import DashboardCardHOC from "../../../../../components/dashboard/DashboardCardHOC";
import DashboardHeader from "../../../../../components/dashboard/DashboardHeader";
import DealDetailsDeal from "./DealDetailsDeal";
import DealDetailsDates from "./DealDetailsDates";
import DealDetailsUploads from "./DealDetailsUploads";
import DealDetailsAdvance from "./DealDetailsCash";
import { getOffer } from "../../../../../store/actions/offers";
import Spinner from "../../../../../components/UI/Spinner";
import { Redirect } from "react-router-dom";

const DealDetails = (props) => {
  const dispatch = useDispatch();
  const [displayOffer, setDisplayOffer] = React.useState(null);
  const [current, setCurrent] = React.useState("Deal");
  const [showCashTooltip, setShowCashTooltip] = React.useState(false);
  const match = useRouteMatch("/deals/:id");
  const offer = useSelector((state) => state.offers.offer);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.offers.loading);
  const dealError = useSelector((state) => state.offers.error);
  React.useEffect(() => {
    if (match.params.id) {
      if (!displayOffer || displayOffer !== match.params.id) {
        setDisplayOffer(match.params.id);
        dispatch(getOffer(token, match.params.id));
      }
    }
  }, [displayOffer, dispatch, match.params.id, token]);

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const dealMenuList = [
    { name: "Deal" },
    { name: "Dates" },
    { name: "Uploads" },
    { name: "Advance" },
  ];

  let document = null;
  let header = null;
  let returnData = (
    <>
      <DashboardHeader title="Deals" />
      <Spinner
        style={{
          width: "50px",
          height: "50px",
          margin: "100px auto",
          fontSize: "50px",
          color: "#bfbfbf",
        }}
      />
    </>
  );
  if (offer) {
    if (offer.Id === match.params.id) {
      if (offer.Status__c === "Dead") {
        return (
          <Redirect
            to={{
              pathname: "/deals/404",
              state: { deadDeal: true },
            }}
          />
        );
      } else if (offer.Status__c === "Offer Out") {
        return <Redirect to="/deals/pending" />;
      }
      header = (
        <DashboardHeader
          title="Deals"
          subtitle={offer.Name}
          route="/deals"
          accepted
        />
      );
      switch (current) {
        case "Deal":
          document = <DealDetailsDeal offer={offer} />;
          break;
        case "Dates":
          document = <DealDetailsDates offer={offer} />;
          break;
        case "Uploads":
          document = <DealDetailsUploads offer={offer} />;
          break;
        case "Advance":
          document = <DealDetailsAdvance offer={offer} />;
          break;
        default:
          break;
      }
      if (!loading) {
        returnData = (
          <>
            {header}
            <div className="page-content">
              <Menu
                onClick={handleClick}
                selectedKeys={[current]}
                mode="horizontal"
                className="deal-menu"
              >
                {dealMenuList.map((item) => {
                  let disabledAdvance = false;
                  if (
                    item.name === "Advance" &&
                    offer.Status__c !== "Sent to Counsel" &&
                    offer.Status__c !== "Court Date Set"
                  ) {
                    disabledAdvance = true;
                  }
                  return (
                    <Menu.Item
                      key={item.name}
                      style={{ position: disabledAdvance ? "relative" : null }}
                      disabled={disabledAdvance}
                      onMouseOut={
                        disabledAdvance ? () => setShowCashTooltip(false) : null
                      }
                      onMouseOver={
                        disabledAdvance ? () => setShowCashTooltip(true) : null
                      }
                    >
                      {item.name}
                      {disabledAdvance && showCashTooltip && (
                        <div
                          className="tooltip"
                          style={{
                            top: "-40px",
                            left: "50px",
                            transform: "translateX(-25%)",
                            lineHight: "16px",
                            height: "unset",
                          }}
                        >
                          <div
                            style={{
                              padding: "1px 3px",
                              lineHeight: "16px",
                              height: "100%",
                              textAlign: "center",
                            }}
                          >
                            Cash Advance Request will be
                            <br />
                            available in later stage
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu>
              <div className="deal-details">{document}</div>
            </div>
          </>
        );
      }
    }
  }
  if (!loading) {
    if (dealError) {
      return (
        <Redirect
          to={{
            pathname: "/deals/404",
            state: { deadDeal: false },
          }}
        />
      );
    }
  }
  return <>{returnData}</>;
};

export default DashboardCardHOC(DealDetails);
