import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import DashboardCardHOC from "../../../components/dashboard/DashboardCardHOC";
import DealOverview from "./Deal/DealOverview";
import DashboardHeader from "../../../components/dashboard/DashboardHeader";
import { getOffers } from "../../../store/actions/offers";
import Spinner from "../../../components/UI/Spinner";

const Deals = (props) => {
  const dispatch = useDispatch();
  const offers = useSelector((state) => state.offers.offers);
  const loading = useSelector((state) => state.offers.loading);
  const token = useSelector((state) => state.auth.token);
  React.useEffect(() => {
    dispatch(getOffers(token));
  }, [dispatch, token]);

  let returnData = (
    <>
      <DashboardHeader title="Deals" />
      <Spinner
        style={{
          width: "50px",
          height: "50px",
          margin: "100px auto",
          fontSize: "50px",
          color: "#bfbfbf",
        }}
      />
    </>
  );

  const pendingOffers = offers.filter(
    (offer) => offer.Status__c === "Offer Out"
  );
  const acceptedOffers = offers.filter(
    (offer) => offer.Status__c !== "Offer Out" && offer.Status__c !== "Dead"
  );
  let pendingOffersReminder = null;
  let acceptedOffersSection = null;
  if (pendingOffers.length !== 0) {
    pendingOffersReminder = (
      <div
        className="pending-deals-section"
        style={{ backgroundColor: "#b7ff96" }}
      >
        <InfoCircleOutlined className="deals-info-icon" />
        <Link to={"/deals/pending"}>
          You have new deals pending approval. &nbsp;
          <span className="deals-info-click">Click Here</span>
        </Link>
      </div>
    );
  }
  if (acceptedOffers.length !== 0) {
    acceptedOffersSection = acceptedOffers.map((acceptedOffer) => (
      <DealOverview
        key={acceptedOffer.Offer_ID__c}
        route={acceptedOffer.Id}
        name={acceptedOffer.Name}
        date={
          acceptedOffer.Contract_Sign_Date__c
            ? acceptedOffer.Contract_Sign_Date__c
            : "Pending.."
        }
        status={acceptedOffer.Status__c}
        description={acceptedOffer.Payment_Stream_Description_Text__c}
      />
    ));
  }

  if (!loading) {
    if (offers.length === 0) {
      returnData = (
        <>
          <DashboardHeader title="Deals" />
          <div className="page-content">No current or pending deals.</div>
        </>
      );
    } else {
      returnData = (
        <>
          <DashboardHeader title="Deals" />
          <div className="page-content">
            {pendingOffersReminder}
            {acceptedOffersSection}
          </div>
        </>
      );
    }
  }

  return <>{returnData}</>;
};

export default DashboardCardHOC(Deals);
