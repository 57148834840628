import React, { Component } from "react";
import MenuList from "./MenuList";
import { StyledSider } from "../../styledComponents/styledComponents";
import { connect } from "react-redux";
import {
  openMenuOnDesktop,
  openMenuOnMobile,
} from "../../../store/actions/menu";

class Sidebar extends Component {
  openMenuOnDesktop = () => {
    this.props.dispatch(openMenuOnDesktop());
  };

  openMenuOnMobile = () => {
    this.props.dispatch(openMenuOnMobile());
  };

  render() {
    return (
      <StyledSider
        trigger={null}
        breakpoint="xl"
        width="160px"
        collapsed={!this.props.menuIsOpen}
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          if (!broken) {
            this.openMenuOnDesktop();
          } else {
            this.openMenuOnMobile();
          }
        }}
      >
        <MenuList
          key={this.props.pathname}
          screens={this.props.screens}
          pathname={this.props.pathname}
        />
      </StyledSider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuIsOpen: state.menu.menuIsOpen,
    menuItem: state.menu.menuItem,
    offerForm: state.form.offerForm,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(Sidebar);
