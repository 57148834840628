import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Input, Button } from "antd";
import { useLocation } from "react-router-dom";

import Recaptcha from "react-google-invisible-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { FORM_RESET } from "../store/actions/types";
import { resetPassword } from "../store/actions/form";

import Spinner from "../components/UI/Spinner";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const captchaRef = React.useRef();
  const [password, setPassword] = React.useState({
    password: {
      value: "",
      errorMessage: "",
    },
    password2: {
      value: "",
      errorMessage: "",
    },
  });
  const [showError, setShowError] = React.useState(false);
  const [token, setToken] = React.useState("");
  const loading = useSelector((state) => state.form.loading);
  const responseMessage = useSelector((state) => state.form.formMessage);
  const responseSuccess = useSelector((state) => state.form.formSuccess);

  const location = useLocation();

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    for (let param of query.entries()) {
      if (param[0] === "token") {
        setToken(param[1]);
      }
    }
  }, [location.search]);

  React.useEffect(
    () => () => {
      dispatch({ type: FORM_RESET });
    },
    [dispatch]
  );

  const onChange = (e) => {
    if (showError) {
      validateAndSetState(e.target.name, e.target.value);
      return;
    }
    setPassword({
      ...password,
      [e.target.name]: {
        ...password[e.target.name],
        value: e.target.value,
      },
    });
  };

  const validate = (updatedState = false) => {
    let value = updatedState;
    if (!updatedState) {
      value = password;
    }
    if (
      value.password.value.length < 8 &&
      value.password.value !== value.password2.value
    ) {
      return {
        password: "Password should be at least 8 characters long!",
        password2: "Passwords do not match",
      };
    } else if (
      value.password.value.length < 8 &&
      value.password.value === value.password2.value
    ) {
      return {
        password: "Password should be at least 8 characters long!",
        password2: "Password should be at least 8 characters long!",
      };
    } else if (value.password.value !== value.password2.value) {
      return {
        password: "",
        password2: "Passwords do not match!",
      };
    } else {
      return {
        password: "",
        password2: "",
      }; //form valid
    }
  };

  const validateAndSetState = (name, value) => {
    const updatedState = {
      password: {
        value: name === "password" ? value : password.password.value,
      },
      password2: {
        value: name === "password2" ? value : password.password2.value,
      },
    };
    let errorMessages = validate(updatedState);
    updatedState.password.errorMessage = errorMessages.password;
    updatedState.password2.errorMessage = errorMessages.password2;
    setPassword(updatedState);
  };

  const submitForm = () => {
    if (!showError) {
      setShowError(true);
    }
    let errorMessages = validate();

    setPassword({
      password: {
        ...password.password,
        errorMessage: errorMessages.password,
      },
      password2: {
        ...password.password2,
        errorMessage: errorMessages.password2,
      },
    });
    if (errorMessages.password === "" && errorMessages.password2 === "") {
      captchaRef.current
        .execute()
        .then((recaptchaToken) => {
          dispatch(
            resetPassword(recaptchaToken, token, password.password.value)
          );
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div
      style={{
        padding: "40px 40px",
        margin: "100px auto",
        border: "1px solid #bfbfbf",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        width: "500px",
        maxWidth: "90%",
      }}
    >
      {!responseSuccess ? (
        <>
          <h2 style={{ fontWeight: "bold", marginBottom: "30px" }}>
            Change your password
          </h2>
          <Input
            name="password"
            type="password"
            placeholder="New Password"
            autoComplete="false"
            style={{ width: "100%", display: "block" }}
            onChange={onChange}
            size="large"
          />
          <div
            style={{
              color: "red",
              fontSize: "13px",
              lineHeight: "20px",
              margin: "0 0 10px 10px",
              textAlign: "left",
              height: "20px",
            }}
          >
            {password.password.errorMessage}
          </div>
          <Input
            name="password2"
            type="password"
            placeholder="Re-enter your password"
            autoComplete="false"
            style={{ width: "100%", display: "block" }}
            onChange={onChange}
            size="large"
          />
          <div
            style={{
              color: "red",
              fontSize: "13px",
              lineHeight: "20px",
              margin: "0 0 10px 10px",
              textAlign: "left",
              height: "20px",
            }}
          >
            {password.password2.errorMessage}
          </div>
          <Recaptcha
            ref={(ref) => (captchaRef.current = ref)}
            sitekey="6Le8F9kdAAAAAD7kh021fhcRixEsLbEyQaTp1W4I"
          />
        </>
      ) : null}

      {loading ? (
        <Spinner
          style={{
            height: "24px",
            width: "24px",
            color: "#bfbfbf",
            fontSize: "24px",
            marginTop: "10px",
          }}
        />
      ) : responseSuccess ? (
        <div
          className="pending-deals-section"
          style={{
            backgroundColor: responseSuccess ? "#b7ff96" : "#ffa196",
            width: "100%",
            textAlign: "center",
            display: "block",
            marginTop: "30px",
          }}
        >
          {responseMessage}
        </div>
      ) : (
        <>
          {!responseSuccess && responseMessage ? (
            <div
              style={{
                color: "red",
                fontSize: "13px",
                lineHeight: "20px",
                margin: "0 0 10px 10px",
                textAlign: "left",
              }}
            >
              Request failed! {responseMessage}
            </div>
          ) : null}
          <Button
            style={{
              display: "block",
              margin: "auto",
              border: "1px solid #ccc",
              borderRadius: "10px",
            }}
            onClick={submitForm}
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
