import React from "react";
import Backdrop from "../Backdrop/Backdrop";

import classes from "./Modal.module.css";

const Modal = (props) => {
  return (
    <>
      <Backdrop show={props.show} closeModal={props.closeModal}></Backdrop>
      <div
        className={classes.Modal}
        style={{
          opacity: props.show ? "1" : "0",
          display: props.show ? "block" : "none",
          padding: props.noPadding ? "0" : null,
          border: props.noBorder ? "none" : null,
          backgroundColor: props.backgroundColor ? props.backgroundColor : null,
          background: props.transparent ? "transparent" : null,
          width: props.width ? props.width : null,
          maxWidth: props.maxwidth ? props.maxwidth : null,
          overflowY: props.overflowY ? props.overflowY : null,
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default Modal;
