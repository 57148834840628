import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { ReactComponent as MobileLogo } from "../../assets/gls_mobile_logo.svg";
import moment from "moment";
import { Link } from "react-router-dom";

import { Form, Input, Button, DatePicker, Space } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import { checkOfferId } from "../../store/actions/register";

export class ReadOffer extends Component {
  state = {
    offerId: this.props.offerId,
    dob: "",
  };

  onSubmit = (e) => {
    this.props.onCheckOfferId(this.state.offerId, this.state.dob);
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onDateChange = (e) => {
    if (e === null) {
      return null;
    } else {
      console.log(e._d);
      const value = moment(e._d).format("YYYY-MM-DD");
      this.setState({
        dob: value,
      });
    }
  };

  render() {
    let error = null;
    if (this.props.error) {
      error = (
        <div
          style={{
            margin: "10px 10px",
            fontSize: "16px",
            color: "red",
          }}
        >
          {this.props.error}
        </div>
      );
    }
    return (
      <motion.div
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn" }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
      >
        <Form
          layout="vertical"
          className="form read-offer-form"
          onFinish={this.onSubmit}
        >
          <div className="form-inner">
            <MobileLogo className="form-logo" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label
                style={{ color: "#ffd700", fontSize: "16px", width: "45%" }}
              >
                Offer ID
              </label>
              <Input
                name="offerId"
                value={this.state.offerId}
                onChange={this.onChange}
                style={{ backgroundColor: "#2e2e2e", width: "55%" }}
                className="input"
                size="large"
                placeholder="Offer ID"
                prefix={<NumberOutlined style={{ color: "#ffd700" }} />}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="yearOfBirth"
                style={{ color: "#ffd700", fontSize: "16px" }}
              >
                Date of Birth
              </label>

              <Space direction="vertical">
                <DatePicker
                  format={"M/D/YYYY"}
                  name="dob"
                  onChange={this.onDateChange}
                  placeholder={"MM/DD/YYYY"}
                  style={{ background: "#2e2e2e" }}
                  className="custom-date-picker"
                />
              </Space>
            </div>

            {error}

            <Button type="primary" htmlType="submit" size="large">
              Continue to Registration
            </Button>
            <p>
              Already have an account? <Link to="/">Log in</Link>
            </p>
          </div>
        </Form>
        {/* <div
          className="only-mobile"
          style={{
            width: "100%",
            maxWidth: "700px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <img src={glsimage} alt="proba" width="100%" height="auto" />
        </div> */}
      </motion.div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckOfferId: (offerId, dob) => dispatch(checkOfferId(offerId, dob)),
  };
};

const mapStateToProps = (state) => {
  return {
    error: state.register.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadOffer);
