import {
  ACCEPTED_OFFER_ID,
  REGISTER_FAILED,
  REGISTER_START,
} from "../actions/types";
import { REJECTED_OFFER_ID, REGISTER_SUCCESS } from "../actions/types";

const initialState = {
  offerId: null,
  error: false,
  dob: "",
  fullName: "",
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case ACCEPTED_OFFER_ID:
      return {
        ...state,
        offerId: action.offerId,
        error: false,
        dob: action.dob,
        fullName: action.fullName,
      };
    case REJECTED_OFFER_ID:
      return {
        ...state,
        offerId: null,
        error: "Please enter a valid Offer ID and Date of Birth",
        dob: "",
        fullName: "",
      };
    case REGISTER_START:
      return {
        ...state,
        error: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        offerId: null,
        dob: "",
        fullName: "",
      };
    case REGISTER_FAILED:
      return {
        ...state,
        error: action.message,
      };
    default:
      return state;
  }
};

export default register;
