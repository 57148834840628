import React from "react";
import { useHistory } from "react-router-dom";
import MenuCardHOC from "../../components/menu/MenuCardHOC";

function About() {
  const history = useHistory();
  const bold = { fontWeight: "bold" };
  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <span
          style={{ textDecoration: "underline" }}
          className="dashboard-title"
        >
          About Us
        </span>
      </div>

      <div
        style={{ marginTop: "5px", display: "flex", justifyContent: "center" }}
      >
        <h2>The Future of Settlement Funding</h2>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2>THE GOLD LEAF DIFFERENCE</h2>
      </div>

      <div style={{ marginTop: "30px", fontSize: "16px" }}>
        <p>
          Gold Leaf Settlement Solutions specializes in purchasing structured
          settlement payments, self-owned annuities, and lottery winnings.
        </p>
        <p>
          Founded in 2016 by experts with over 25 years of experience in the
          structured settlement space, Gold Leaf has quickly become an industry
          leader in purchasing future payment streams by prioritizing our
          customers’ compensation over our profits.
        </p>
        <p>
          Now we aim to eliminate the common frustrations that can occur when
          transferring structured settlement payments, and our new digital
          platform does just that.
        </p>
        <p>
          We understand that making financial decisions can be stressful and
          overwhelming. Working with us, you can be at ease knowing that you
          will get the best possible cash offer for your settlement payments
          without feeling compromised.
        </p>
        <p>
          Our proprietary, easy-to-use online platform ensures you get what you
          deserve; <span style={bold}>low-interest rates</span>, complete
          <span style={bold}> transparency</span>, and a{" "}
          <span style={bold}>fast</span>, <span style={bold}>seamless</span>{" "}
          transfer process.
        </p>
        <p>
          Gold Leaf is committed to empowering you through your finances with
          honesty and reliability, keeping you updated throughout the process,
          and offering 24/7 first-class support from real-life funding
          specialists. We are here for you every step of the way.
        </p>
        <p>
          Your comfort and confidence are valuable to us, and your satisfaction
          is our top priority. That is the Gold Leaf Difference.
        </p>
        <p style={bold}>
          <span
            onClick={() => {
              history.push("/how-it-works");
            }}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Click here
          </span>{" "}
          to learn more about our new digital platform and get started.
        </p>
      </div>
    </div>
  );
}

export default MenuCardHOC(About);
