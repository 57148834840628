import {
  GET_NOTIFICATIONS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  NOTIFICATIONS_LOADING,
  NOTIFICATION_READ,
  TEST_TOKEN_SUCCESS,
  USERS_ME,
} from "../actions/types";

const initialState = {
  newNotifications: [],
  notifications: [],
  loading: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_TOKEN_SUCCESS:
      return {
        ...state,
        newNotifications: action.response.notifications.filter(
          (notif) =>
            notif.notification_type === "dashboard" &&
            notif.read_datetime === null
        ),
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        newNotifications: action.response.notifications.filter(
          (notif) =>
            notif.notification_type === "dashboard" &&
            notif.read_datetime === null
        ),
      };
    case NOTIFICATION_READ:
      const notifications = [...state.notifications];
      const newNotifications = state.newNotifications.filter(
        (notif) => notif.id !== action.notificationId
      );
      const index = notifications.findIndex(
        (notif) => notif.id === action.notificationId
      );
      notifications[index].read_datetime = new Date().toISOString();
      return {
        ...state,
        newNotifications: newNotifications,
        notifications: notifications,
      };
    case GET_NOTIFICATIONS:
      const allNotifications = action.response.filter(
        (notification) => notification.notification_type === "dashboard"
      );
      return {
        ...state,
        notifications: allNotifications,
        newNotifications: action.response.filter(
          (notif) =>
            notif.notification_type === "dashboard" &&
            notif.read_datetime === null
        ),
        loading: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_ME:
      return {
        ...state,
        newNotifications: action.response.notifications.filter(
          (notif) =>
            notif.notification_type === "dashboard" &&
            notif.read_datetime === null
        ),
        notifications: action.response.notifications.filter(
          (notif) => notif.notification_type === "dashboard"
        ),
      };
    default:
      return state;
  }
};

export default reducer;
