import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import form from "./form";
import offers from "./offers";
import register from "./register";
import notifications from "./notifications";
import uploads from "./uploads";

export default combineReducers({
  auth,
  menu,
  form,
  offers,
  register,
  notifications,
  uploads,
});
