import React from "react";
import { Button } from "antd";

import PendingDealRow from "../../../../components/accounts/PendingDealRow";
import { useDispatch, useSelector } from "react-redux";
import { offerAccepted, offerDeclined } from "../../../../store/actions/offers";
import Modal from "../../../../components/UI/Modal/Modal";
import AcceptingOfferModal from "../../../../components/UI/Modal/AcceptingOfferModal";
import DeclineForm from "../../../../components/UI/Modal/DeclineForm";

const PendingDeal = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalType, setModalType] = React.useState("accept");
  const token = useSelector((state) => state.auth.token);

  const toggleModal = (type) => {
    setIsModalVisible((prevState) => !prevState);
    if (type) {
      setModalType(type);
    }
  };

  const displayNumber = (number) => {
    if (!number) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const dispatch = useDispatch();
  const clickHandler = (action, denyReason) => {
    if (action === "accept") {
      dispatch(offerAccepted(props.offer.Id, token));
      console.log(props.offer.Id, action);
    } else if (action === "decline") {
      dispatch(
        offerDeclined(
          props.offer.Id,
          token,
          props.numberOfPendingOffers,
          denyReason
        )
      );
      console.log(props.offer.Id, action, denyReason);
    }
  };
  let createdDate = new Date(props.offer.CreatedDate);
  const year = createdDate.getFullYear();
  const month = createdDate.getMonth() + 1;
  const dt = createdDate.getDate();
  const createdDatecustom = month + "/" + dt + "/" + year;

  return (
    <div className="pending-deal">
      <div className="pending-deal-fields">
        <div className="pending-deal-name">{props.offer.Name}</div>
        <PendingDealRow property={"Date created"} value={createdDatecustom} />
        <PendingDealRow
          long
          property="Payment Stream Description"
          value={
            props.offer.Payment_Stream_Description_Text__c
              ? props.offer.Payment_Stream_Description_Text__c
              : "-"
          }
        />
        {/* <PendingDealRow
          property={"Legal Fees"}
          value={displayNumber(props.offer.Legal_Fees__c)}
        /> */}
        {/* <PendingDealRow
          property={"Effective Interest Rate"}
          value={displayNumber(props.offer.Effective_Interest_Rate__c) + "%"}
        /> */}
        <PendingDealRow
          property={"Total Payments"} // previously was aggregate
          value={
            props.offer.Aggregate__c
              ? "$" + displayNumber(props.offer.Aggregate__c)
              : "-"
          }
        />
        <PendingDealRow
          property={"Nominal Interest Rate"}
          value={
            props.offer.Nominal__c
              ? displayNumber(props.offer.Nominal__c) + " %"
              : "-"
          }
        />
        <PendingDealRow
          property={"Amount Payable to You"}
          value={
            props.offer.Purchase_Price_Amount__c
              ? "$" + displayNumber(props.offer.Purchase_Price_Amount__c)
              : "$170,000"
          }
          bigger
        />
        <div style={{ marginTop: "10px", fontSize: "11px" }}>
          *The offer is only valid after annuity payments are verified
        </div>
      </div>
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          className="accept-btn"
          // onClick={clickHandler.bind(this, "accept")}
          onClick={toggleModal.bind(this, "accept")}
        >
          ACCEPT
        </Button>
        <Button
          className="decline-btn"
          // onClick={clickHandler.bind(this, "decline")}
          onClick={toggleModal.bind("decline")}
        >
          DECLINE
        </Button>
      </div>
      <Modal show={isModalVisible} closeModal={toggleModal}>
        {modalType === "accept" ? (
          <AcceptingOfferModal
            offer={props.offer}
            toggleModal={toggleModal}
            clickHandler={clickHandler}
            numberOfPendingOffers={props.numberOfPendingOffers}
          />
        ) : (
          <DeclineForm toogleModal={toggleModal} clickHandler={clickHandler} />
        )}
      </Modal>
    </div>
  );
};

export default PendingDeal;
