import React from "react";
import { Form, Input, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Spinner from "../Spinner";
import Recaptcha from "react-google-invisible-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { requestOffer } from "../../../store/actions/form";

const RequestOffer = (props) => {
  const captchaRef = React.useRef();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.form.loading);
  const submitSuccess = useSelector((state) => state.form.formSuccess);
  const submitMessage = useSelector((state) => state.form.formMessage);

  const [requestForm, setRequestForm] = React.useState({
    name: {
      value: "",
      required: true,
      message: null,
    },
    lastName: {
      value: "",
      required: true,
      message: null,
    },
    email: {
      value: "",
      required: true,
      message: null,
    },
    phone: {
      value: "",
      required: true,
      message: null,
    },
    payments: {
      value: "",
      required: true,
      message: null,
    },
  });
  const [showError, setShowError] = React.useState(false);

  const onSubmit = () => {
    if (!checkFormValidity()) {
      setShowError(true);
      return;
    } else {
      captchaRef.current
        .execute()
        .then((recaptchaToken) => {
          dispatch(
            requestOffer(
              recaptchaToken,
              requestForm.email.value,
              requestForm.phone.value,
              requestForm.payments.value,
              requestForm.name.value,
              requestForm.lastName.value
            )
          );
        })
        .catch((err) => console.log(err));
    }
  };

  const checkFormValidity = () => {
    let formValid = true;
    let updatedRequestForm = {
      ...requestForm,
    };
    for (let key in requestForm) {
      let newErrorMessage = validate(key);
      updatedRequestForm[key] = {
        ...requestForm[key],
        message: newErrorMessage,
      };
      if (newErrorMessage) {
        formValid = false;
      }
    }
    setRequestForm(updatedRequestForm);
    return formValid;
  };

  const validate = (name, value = false) => {
    if (value === false) {
      value = requestForm[name].value;
    }
    switch (name) {
      case "name":
        return value.trim().length > 0 ? null : "Required";
      case "lastName":
        return value.trim().length > 0 ? null : "Required";
      case "email":
        const pattern =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return pattern.test(value)
          ? null
          : "Please enter a valid email address";
      case "phone": {
        return null;
      }
      case "payments":
        return value.length === 0 ? "Required" : null;
      default:
        return;
    }
  };

  const onChange = (e) => {
    let newMessage = null;
    if (requestForm[e.target.name].message || showError) {
      newMessage = validate(e.target.name, e.target.value);
    }
    const updatedFormElement = {
      ...requestForm[e.target.name],
      message: newMessage,
      value: e.target.value,
    };
    setRequestForm({
      ...requestForm,
      [e.target.name]: updatedFormElement,
    });
  };

  const errorDiv = {
    height: "20px",
    color: "red",
    fontSize: "13px",
    lineHeight: "20px",
    margin: "0 0 10px 10px",
    textAlign: "left",
  };

  let form = (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      style={{ fontSize: "16px", padding: "20px" }}
    >
      <div style={{ textAlign: "center" }}>
        Fill out our form and get an offer!
      </div>
      <div className="flex-row" style={{ marginTop: "30px" }}>
        <div className="requestOfferFullName">
          <div className="requestOfferNameField">
            <Input
              name="name"
              style={{ width: "100%" }}
              disabled={submitSuccess}
              autoComplete="off"
              placeholder="First Name"
              onChange={onChange}
              size="large"
              value={requestForm.name.value}
            />

            <div style={errorDiv}>{requestForm.name.message}</div>
          </div>
          <div className="requestOfferNameField">
            <Input
              name="lastName"
              disabled={submitSuccess}
              style={{ width: "100%" }}
              autoComplete="off"
              placeholder="Last Name"
              onChange={onChange}
              size="large"
              value={requestForm.lastName.value}
            />
            <div style={errorDiv}>{requestForm.lastName.message}</div>
          </div>
        </div>
      </div>
      <div>
        <Input
          name="email"
          type="email"
          disabled={submitSuccess}
          placeholder="email"
          autoComplete="off"
          style={{ width: "100%" }}
          onChange={onChange}
          size="large"
          value={requestForm.email.value}
        />
        <div style={errorDiv}>{requestForm.email.message}</div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Input
          name="phone"
          disabled={submitSuccess}
          placeholder="Phone Number (optional)"
          autoComplete="off"
          style={{ width: "100%" }}
          onChange={onChange}
          size="large"
          value={requestForm.phone.value}
        />
      </div>
      <div>
        <TextArea
          rows={5}
          disabled={submitSuccess}
          autoComplete="off"
          placeholder="Payments"
          name="payments"
          style={{ width: "100%" }}
          maxLength="255"
          onChange={onChange}
          size="large"
          value={requestForm.payments.value}
        />
        <div style={errorDiv}>{requestForm.payments.message}</div>
      </div>
      <div>
        <Recaptcha
          ref={(ref) => (captchaRef.current = ref)}
          sitekey="6Le8F9kdAAAAAD7kh021fhcRixEsLbEyQaTp1W4I"
        />
      </div>
      {!submitSuccess && submitMessage ? (
        <div style={{ color: "red" }}>{submitMessage}</div>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {loading ? (
          <Spinner
            style={{
              height: "24px",
              width: "24px",
              color: "#bfbfbf",
              fontSize: "24px",
              marginTop: "10px",
            }}
          />
        ) : submitSuccess ? (
          <div
            className="pending-deals-section"
            style={{
              backgroundColor: submitSuccess ? "#b7ff96" : "#ffa196",
              width: "100%",
              textAlign: "center",
              display: "block",
              marginTop: "30px",
            }}
          >
            {submitMessage}
          </div>
        ) : (
          <Button
            onClick={onSubmit}
            style={{
              display: "inline-block",
              border: "1px solid #ccc",
              borderRadius: "10px",
            }}
          >
            Request an offer!
          </Button>
        )}
      </div>
    </Form>
  );

  return form;
};

export default RequestOffer;
