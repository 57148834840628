import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearOfferError } from "../../store/actions/offers";
import DashboardCardHOC from "../dashboard/DashboardCardHOC";
import DashboardHeader from "../dashboard/DashboardHeader";

const NonExistingOffer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  if (history) {
    if (history.location) {
      if (history.location.state) {
        if (history.location.state.deadDeal) {
          console.log(history.location.state.deadDeal, "deadDeal");
        }
      }
    }
  }

  React.useEffect(() => {
    dispatch(clearOfferError());
  }, [dispatch]);
  return (
    <>
      <DashboardHeader title="Deal not found" />
      <div className="page-content">
        Deal you are searching can not be found.
      </div>
    </>
  );
};

export default DashboardCardHOC(NonExistingOffer);
