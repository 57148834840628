import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import {
  DELETE_FILE,
  UPLOAD_DOCUMENT_LOADING,
  UPLOAD_DOCUMENT_SUCCESS,
} from "./types";

export const uploadDocuments =
  (token, file, document_type, deal_id) => (dispatch) => {
    dispatch({ type: UPLOAD_DOCUMENT_LOADING, doc_type: document_type });
    const config = {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    formData.append("file", file);
    formData.append("document_type", document_type);
    axios
      .post(
        BASE_URL + "api/v1/users/me/deals/" + deal_id + "/upload",
        formData,
        config
      )
      .then((response) => {
        dispatch({
          type: UPLOAD_DOCUMENT_SUCCESS,
          doc_type: document_type,
          file: file,
          download_url: response.data.download_url,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const downloadFile = (doc, token) => (dispatch) => {
  // const token = localStorage.getItem("token");
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
    responseType: "blob",
  };
  axios
    .get(doc.download_url, config)
    .then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", doc.file_name); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteFile = (doc, token) => (dispatch) => {
  // const token = localStorage.getItem("token");
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios
    .delete(doc.download_url, config)
    .then((response) => {
      dispatch({ type: DELETE_FILE, doc: doc });
    })
    .catch((error) => {
      console.log(error);
    });
};
