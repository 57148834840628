import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import {
  CUSTOMER_SUPPORT_REQUEST,
  CUSTOMER_SUPPORT_REQUEST_START,
  FORM_LOADING,
  OPEN_OFFER_FORM,
  REQUEST_OFFER,
  REQUEST_OFFER_ERROR,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "./types";

export const openReadOfferForm = () => ({ type: OPEN_OFFER_FORM });

export const submitCustomerSupportRequest =
  (subject, message, dealId, sendCopy, token) => (dispatch) => {
    dispatch({ type: CUSTOMER_SUPPORT_REQUEST_START });
    const requestBody = {
      subject: subject,
      body: message,
      deal_id: dealId,
      send_me_a_copy: sendCopy,
    };
    const config = {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(BASE_URL + "api/v1/users/me/cs_request", requestBody, config)
      .then((response) => {
        dispatch({ type: CUSTOMER_SUPPORT_REQUEST, data: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const requestOffer =
  (reCAPTCHAtoken, email, phone, payments, firstName, lastName) =>
  (dispatch) => {
    dispatch({ type: FORM_LOADING });
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      recaptcha: {
        token: reCAPTCHAtoken,
      },
      user: {
        email: email,
        phone: phone,
        details: payments,
        first_name: firstName,
        last_name: lastName,
      },
    };
    axios
      .post(
        BASE_URL + "api/v1/users/visitor-request-offer",
        requestBody,
        config
      )
      .then((response) => {
        console.log(response);
        dispatch({ type: REQUEST_OFFER, payload: response.data });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: REQUEST_OFFER_ERROR, payload: error.response.data });
      });
  };

export const recoverPassword = (email, recaptchaToken) => (dispatch) => {
  dispatch({ type: FORM_LOADING });
  const config = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const requestBody = {
    token: recaptchaToken,
  };
  axios
    .post(BASE_URL + "api/v1/password-recovery/" + email, requestBody, config)
    .then((response) => {
      console.log(response);
      dispatch({ type: RECOVER_PASSWORD_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      console.log(error.response);
      dispatch({ type: RECOVER_PASSWORD_FAIL, payload: error.response });
    });
};

export const resetPassword =
  (recaptchaToken, token, newPassword) => (dispatch) => {
    dispatch({ type: FORM_LOADING });
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      recaptcha: {
        token: recaptchaToken,
      },
      token: token,
      new_password: newPassword,
    };
    axios
      .post(BASE_URL + "api/v1/reset-password/", requestBody, config)
      .then((response) => {
        console.log(response);
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        dispatch({ type: RESET_PASSWORD_FAIL, payload: error.response });
      });
  };
