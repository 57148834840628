import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import {
  GET_NOTIFICATIONS,
  NOTIFICATIONS_LOADING,
  NOTIFICATION_READ,
} from "./types";

export const notificationRead = (token, notificationId) => (dispatch) => {
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const requestBody = {
    read_datetime: new Date().toISOString(),
    state: "sent",
  };
  axios
    .put(
      BASE_URL + "api/v1/users/me/notifications/" + notificationId,
      requestBody,
      config
    )
    .then((response) => {
      dispatch({ type: NOTIFICATION_READ, notificationId: notificationId });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getNotifications = (token) => (dispatch) => {
  dispatch({ type: NOTIFICATIONS_LOADING });
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios
    .get(BASE_URL + "api/v1/users/me/notifications/", config)
    .then((response) => {
      dispatch({ type: GET_NOTIFICATIONS, response: response.data });
    })
    .catch((error) => {
      console.log(error);
    });
};
