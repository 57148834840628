import React from "react";
import DesktopLinks from "./LoggedOut/Desktop";
import MobileLinks from "./LoggedOut/Mobile";

function LoggedOutLinks({ screens, pathname }) {
  return !screens.xl ? (
    <MobileLinks screens={screens} pathname={pathname} />
  ) : (
    <DesktopLinks />
  );
}

export default LoggedOutLinks;
