import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocuments } from "../../../store/actions/uploads";

const WebCam = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [hasPhoto, setHasPhoto] = React.useState(false);
  const videoRef = React.useRef();
  const photoRef = React.useRef();

  const setVideoStream = props.setVideoStream;

  const closeModal = props.closeModal;

  React.useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 1920, height: 1080 },
      })
      .then((stream) => {
        setVideoStream(stream);
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        return stream;
      })
      .catch((error) => {
        console.log(error);
        closeModal(true);
      });
  }, [setVideoStream, closeModal]);

  const startUploading = () => {
    let photo = photoRef.current;
    photo.toBlob((blob) => {
      const file = new File([blob], "webcam_image.png");
      dispatch(uploadDocuments(token, file, props.documentType, props.offerId));
    });
    props.closeModal();
  };

  const takePhoto = () => {
    const width = 1280;
    const height = 720;

    let video = videoRef.current;
    let photo = photoRef.current;
    photo.width = width;
    photo.height = height;

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);
    setHasPhoto(true);
  };

  return (
    <>
      <div className={hasPhoto ? "camera hasPhoto" : "camera"}>
        <video ref={videoRef}></video>
      </div>
      <div className={hasPhoto ? "photo hasPhoto" : "photo"}>
        <canvas ref={photoRef}></canvas>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          background: "none",
        }}
      >
        {hasPhoto ? (
          <>
            <div
              className="photoBtn"
              style={{ marginRight: "20px" }}
              onClick={() => setHasPhoto(false)}
            >
              Take a new Photo
            </div>
            <div className="photoBtn" onClick={startUploading}>
              Upload
            </div>
          </>
        ) : (
          <div className="photoBtn" onClick={takePhoto}>
            Take a Photo
          </div>
        )}
      </div>
    </>
  );
};

export default WebCam;
