import React, { Component } from "react";
import Login from "../pages/Login";
import ReadOffer from "../components/accounts/ReadOffer";
import { connect } from "react-redux";

import { Layout, Row, Col } from "antd";

const { Content } = Layout;

class Landing extends Component {
  render() {
    return (
      <Content className="landing-sub landing-margin">
        <Row justify="space-between">
          <Col
            xs={0}
            sm={0}
            md={12}
            lg={12}
            xl={14}
            style={{ margin: "0 auto" }}
            className="mobile-tablet-show"
          >
            <div className="heading-desktop">
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "44px",
                }}
              >
                Structured settlement <br /> funding made simple
              </div>
              <p>Trust us to get you more.</p>
            </div>
            <div className="heading-desktop">
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "40px",
                  marginTop: "20px",
                }}
              >
                Reach Financial Freedom
              </div>
              <p style={{ fontSize: "18px" }}>
                Gold Leaf Settlement Soultions is redefining the process of
                transferring future assets with our new, easy-to-use online
                platform created to relieve our customers of the headaches that
                often come with structured settlement transactions. We have
                helped thousands of clients nationwide reach financial freedom.
                Give us the opportunity to do the same for you.
              </p>
              <p style={{ fontSize: "18px" }}>
                Contact our live support team at (844) 989-3171 for questions
                regarding our new digital platform.
              </p>
            </div>
          </Col>
          <Col className="form-container">
            {this.props.offerForm ? <ReadOffer /> : <Login />}
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerForm: state.form.offerForm,
    user: state.auth.user,
    isAuth: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Landing);
