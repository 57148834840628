import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Form, Input, Button, Layout, Row, Col } from "antd";
import { registerStart } from "../../store/actions/register";
import moment from "moment";
import "moment-timezone";

const { Content } = Layout;

export class RegisterForm extends Component {
  state = {
    full_name: {
      value: this.props.fullName,
      required: true,
      errorMessage: "",
    },
    dob: {
      value: this.props.dob,
      required: true,
      errorMessage: "",
    },
    email: {
      value: "",
      required: true,
      errorMessage: "",
    },
    password: {
      value: "",
      required: true,
      errorMessage: "",
    },
    password2: {
      value: "",
      required: true,
      errorMessage: "",
    },
    phoneNumber: {
      value: "",
      required: false,
      errorMessage: "",
    },
    touched: false,
  };

  checkValidity = (element, inputName) => {
    switch (inputName) {
      case "full_name":
        if (element.value.trim() === "") {
          return "Required!";
        } else {
          return "";
        }
      case "dob":
        if (
          element.value.length !== 10 ||
          element.value.split("-").length !== 3
        ) {
          return "Please enter a valid date!";
        } else {
          return "";
        }
      case "password":
        if (element.value.length < 8) {
          return "Password should be at least 8 characters long!";
        } else {
          return "";
        }
      case "password2":
        if (element.value !== this.state.password.value) {
          return "Passwords do not match!";
        } else if (element.value.length < 8) {
          return "Password should be at least 8 characters long!";
        } else {
          return "";
        }
      case "email":
        const pattern =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (pattern.test(element.value)) {
          return "";
        } else {
          return "Please enter a valid email address";
        }
      case "phoneNumber":
        return "";
      default:
        return;
    }
  };

  onSubmit = (event) => {
    const { full_name, dob, email, password, phoneNumber } = this.state;
    let errorMessages = {};
    for (let key in this.state) {
      if (key === "touched") continue;
      errorMessages[key] = this.checkValidity(this.state[key], key);
    }
    let formValid = true;
    for (let key in errorMessages) {
      if (errorMessages[key] !== "") {
        formValid = false;
        break;
      }
    }
    if (!formValid) {
      const updatedState = {
        ...this.state,
        full_name: {
          ...this.state.full_name,
          errorMessage: errorMessages.full_name,
        },
        email: {
          ...this.state.email,
          errorMessage: errorMessages.email,
        },
        dob: {
          ...this.state.dob,
          errorMessage: errorMessages.dob,
        },
        password: {
          ...this.state.password,
          errorMessage: errorMessages.password,
        },
        password2: {
          ...this.state.password2,
          errorMessage: errorMessages.password2,
        },
        touched: true,
      };
      this.setState({
        full_name: updatedState.full_name,
        dob: updatedState.dob,
        email: updatedState.email,
        password: updatedState.password,
        password2: updatedState.password2,
        phoneNumber: updatedState.phoneNumber,
        touched: updatedState.touched,
      });
      return;
    }

    if (formValid) {
      this.props.onRegister(
        full_name.value,
        dob.value,
        email.value,
        password.value,
        phoneNumber.value,
        this.props.validOfferId,
        moment.tz.guess()
      );
    }
  };
  onChange = (e) => {
    const updatedState = {
      ...this.state,
    };
    const updatedElement = {
      ...updatedState[e.target.name],
    };
    if (e.target.name === "phoneNumber") {
      updatedElement.value = e.target.value.replace(/[^0-9+]/g, "");
    } else {
      updatedElement.value = e.target.value;
    }
    if (this.state.touched) {
      updatedElement.errorMessage = this.checkValidity(
        updatedElement,
        e.target.name
      );
    }
    this.setState({ [e.target.name]: updatedElement });
  };

  render() {
    const { full_name, dob, email, password, password2, phoneNumber } =
      this.state;
    const errorDiv = {
      color: "red",
      fontSize: "13px",
      margin: "5px 0 0 0",
      textAlign: "left",
    };

    const errorStyle = {
      borderColor: "red",
    };

    const validStyle = {
      borderColor: "green",
    };

    const DD = this.state.dob.value.split("-")[2];
    const MM = this.state.dob.value.split("-")[1];
    const YYYY = this.state.dob.value.split("-")[0];

    return (
      <Content className="landing-sub">
        <Row justify="center">
          <Col>
            <motion.div
              animate={{ opacity: 1 }}
              transition={{ ease: "easeIn" }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
            >
              <Form
                name="registration"
                layout="vertical"
                className="form register-form"
                autoComplete="off"
                onFinish={this.onSubmit}
              >
                <input
                  autoComplete="off"
                  name="hidden"
                  type="text"
                  style={{ display: "none" }}
                ></input>
                <div className="form-inner">
                  <h3>Sign up to use our digital dashboard. It's easy</h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <label
                      htmlFor="dob"
                      name="dob"
                      value={dob}
                      style={{ fontSize: "16px" }}
                    >
                      Date of Birth
                    </label>
                    <Input
                      className="input"
                      style={{ background: "#2e2e2e", width: "60%" }}
                      name="dateOfBirth"
                      size="large"
                      value={MM + "/" + DD + "/" + YYYY}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="offer_id"
                      name="offer_id"
                      style={{ fontSize: "16px" }}
                    >
                      Offer ID
                    </label>
                    <Input
                      style={{ background: "#2e2e2e", width: "60%" }}
                      name="offer_id"
                      className="input"
                      // onChange={this.onChange}
                      size="large"
                      value={this.props.validOfferId}
                    />
                  </div>
                  <Input
                    style={
                      this.state.touched
                        ? this.state.full_name.errorMessage === ""
                          ? validStyle
                          : errorStyle
                        : null
                    }
                    name="full_name"
                    type="name"
                    autoComplete="off"
                    // onChange={this.onChange}
                    value={full_name.value}
                    className="input"
                    size="large"
                    placeholder="Full Name"
                  />
                  {this.state.touched ? (
                    <div style={errorDiv}>
                      {this.state.full_name.errorMessage}
                    </div>
                  ) : null}
                  <Input
                    style={
                      this.state.touched
                        ? this.state.email.errorMessage === ""
                          ? validStyle
                          : errorStyle
                        : null
                    }
                    size="large"
                    placeholder="Email"
                    type="email"
                    className="input"
                    name="email"
                    onChange={this.onChange}
                    value={email.value}
                  />
                  {this.state.touched ? (
                    <div style={errorDiv}>{this.state.email.errorMessage}</div>
                  ) : null}
                  <Input
                    style={
                      this.state.touched
                        ? this.state.password.errorMessage === ""
                          ? validStyle
                          : errorStyle
                        : null
                    }
                    size="large"
                    placeholder="Password"
                    type="password"
                    className="input"
                    name="password"
                    onChange={this.onChange}
                    value={password.value}
                  />
                  {this.state.touched ? (
                    <div style={errorDiv}>{password.errorMessage}</div>
                  ) : null}
                  <Input
                    style={
                      this.state.touched
                        ? this.state.password2.errorMessage === ""
                          ? validStyle
                          : errorStyle
                        : null
                    }
                    size="large"
                    placeholder="Re-enter Password"
                    type="password"
                    className="input"
                    name="password2"
                    onChange={this.onChange}
                    value={password2.value}
                  />
                  {this.state.touched ? (
                    <div style={errorDiv}>{password2.errorMessage}</div>
                  ) : null}
                  <Input
                    name="phoneNumber"
                    onChange={this.onChange}
                    className="input"
                    value={phoneNumber.value}
                    size="large"
                    placeholder="Phone Number  (optional)"
                  />
                  {this.state.touched ? (
                    <div style={errorDiv}>{phoneNumber.errorMessage}</div>
                  ) : null}

                  <Button
                    className="registerBtn"
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Register
                  </Button>
                  {this.props.registrationError ? (
                    <div style={{ ...errorDiv, textAlign: "center" }}>
                      {this.props.registrationError}
                    </div>
                  ) : null}
                </div>
              </Form>
            </motion.div>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  validOfferId: state.register.offerId,
  dob: state.register.dob,
  fullName: state.register.fullName,
  registrationError: state.register.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onRegister: (
      fullName,
      dob,
      email,
      password,
      phoneNumber,
      offerId,
      timezone
    ) =>
      dispatch(
        registerStart(
          fullName,
          dob,
          email,
          password,
          phoneNumber,
          offerId,
          timezone
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
