import React from "react";
import { Row, Col } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const DashboardHeader = (props) => {
  let header = <span className="dashboard-title">{props.title}</span>;
  if (props.subtitle) {
    header = (
      <>
        <Link to={props.route}>
          <span className="dashboard-title dashboard-title-link">
            {props.title}
          </span>
        </Link>
        <DoubleRightOutlined className="dashboard-subtitle-icon" />
        <span className="dashboard-title">{props.subtitle}</span>
        {props.accepted ? <div className="accept-label">Accepted</div> : null}
      </>
    );
  }
  return (
    <Row className="ant-row-header">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className="dashboard-header">{header}</div>
      </Col>
    </Row>
  );
};

export default DashboardHeader;
