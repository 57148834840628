import {
  MENU_OPENED,
  SCREEN_IS_DESKTOP,
  SCREEN_IS_MOBILE,
  MENU_ITEM_SELECTED,
  MENU_CLOSED,
} from "../actions/types";

const initialState = {
  menuIsOpen: false,
  menuItem: "",
};

const reducer = (state = initialState, action) => {
  const menuIsOpen = state.menuIsOpen;
  switch (action.type) {
    case MENU_OPENED:
      return {
        ...state,
        menuIsOpen: !menuIsOpen,
      };
    case MENU_CLOSED:
      return {
        ...state,
        menuIsOpen: false,
      };
    case SCREEN_IS_DESKTOP:
      return {
        ...state,
        menuIsOpen: true,
      };
    case SCREEN_IS_MOBILE:
      return {
        ...state,
        menuIsOpen: false,
      };
    case MENU_ITEM_SELECTED:
      if (menuIsOpen) {
        return {
          ...state,
          menuItem: action.payload.menuItem,
          menuIsOpen: action.payload.menuIsOpen,
        };
      }
      break;
    default:
      return state;
  }
};

export default reducer;
