import React from "react";
import DealDetailRow from "../../../../../components/accounts/DealDetailRow";
import moment from "moment";
import "moment-timezone";
import { useSelector } from "react-redux";

const DealDetailsDates = (props) => {
  const userTimezone = useSelector((state) => state.auth.timezone);

  const pendingDate = "Pending...";
  const displayDate = (date) => {
    if (!date) {
      return;
    }
    return moment(date).tz(userTimezone).format("L");
  };
  const displayDateWithTimeAndTimeZone = (date) => {
    if (!date) {
      return;
    }
    return moment(date).tz(userTimezone).format("L LT, z"); //MM/DD/YYYY, h:mm A
  };
  return (
    <>
      <DealDetailRow
        property={"Offer created:"}
        offerField={displayDate(props.offer.CreatedDate)}
      />
      <DealDetailRow
        property={"Contract Sign Date:"}
        offerField={
          props.offer.Contract_Sign_Date__c
            ? displayDate(props.offer.Contract_Sign_Date__c)
            : pendingDate
        }
      />
      <DealDetailRow
        property={"Court Date:"}
        offerField={
          props.offer.Court_Date__c
            ? displayDateWithTimeAndTimeZone(props.offer.Court_Date__c)
            : pendingDate
        }
      />
      {props.offer.Court_Date__c ? (
        <DealDetailRow
          property={"Court Location:"}
          offerField={props.offer.Jurisdiction__c}
        />
      ) : null}
      <DealDetailRow
        property={"Funded date:"}
        offerField={
          props.offer.Funding_Date__c
            ? displayDate(props.offer.Funding_Date__c)
            : pendingDate
        }
      />
    </>
  );
};

export default DealDetailsDates;
