import React from "react";

import CashRequestComponent from "../../../../../components/accounts/CashRequest";

const DealDetailsAdvance = (props) => {
  return (
    <>
      <CashRequestComponent deal_id={props.offer.Id} />
    </>
  );
};

export default DealDetailsAdvance;
