import React from "react";
import { Layout, Grid } from "antd";
import { Switch, Link, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Sidebar from "./components/layout/sidebar/Sidebar";
import MenuCardContainer from "./components/menu/MenuCardContainer";
import Navigation from "./components/layout/Navigation";
import FooterNavigation from "./components/layout/FooterNavigation";
import Landing from "./pages/Landing";
import Disclaimer from "./components/accounts/Disclaimer";
import DealDetails from "./pages/dashboard/Deals/Deal/DealDetails/DealDetails";
import Notifications from "./pages/dashboard/Notifications";
import MyDashboard from "./pages/dashboard/MyDashboard";
import Register from "./pages/Register";
import { ReactComponent as MainLogo } from "./assets/gls_big_logo.svg";
import { loadUser, readUserMe } from "./store/actions/auth";

import Profile from "./pages/dashboard/Profile";
import Deals from "./pages/dashboard/Deals/Deals";
import PendingDeals from "./pages/dashboard/Deals/PendingDeals";
import NonExistingOffer from "./components/accounts/NonExistingOffer";
import Recover from "./pages/Recover";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const { Header, Footer } = Layout;

const { useBreakpoint } = Grid;

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userFullName = useSelector((state) => state.auth.userFullName);
  const loading = useSelector((state) => state.auth.isLoading);

  let { pathname } = useLocation();
  const screens = useBreakpoint();
  const token = useSelector((state) => state.auth.token);
  React.useEffect(() => {
    if (userFullName) dispatch(readUserMe(token));
  }, [dispatch, token, userFullName, pathname]);

  let routes = (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/register" component={Register} />
      <Route path="/recover" component={Recover} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route component={MenuCardContainer} />
    </Switch>
  );

  if (isAuth) {
    routes = (
      <Switch>
        {userFullName ? null : (
          <Route exact path="/register" component={Register} />
        )}
        <Route exact path="/mydashboard" component={MyDashboard} />
        <Route exact path="/notifications" component={Notifications} />
        <Route exact path="/deals" component={Deals} />
        <Route exact path="/deals/pending" component={PendingDeals} />
        <Route
          exact
          path="/deals/404"
          render={(props) => <NonExistingOffer {...props} />}
        />
        <Route
          exact
          path="/deals/:id"
          render={(props) => <DealDetails props={props} />}
        />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/profile" component={Profile} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route component={MenuCardContainer} />
      </Switch>
    );
  }

  let data = null;
  if (loading === false) {
    data = (
      <Layout className="dashboard">
        {(screens.xl || screens.lg) &&
        (pathname === "/" || pathname === "/register") ? (
          <Header
            style={{
              height: "fit-content",
              background: "none",
              textAlign: "center",
            }}
            className="desktop-header"
          >
            <Link to="/">
              <MainLogo />
            </Link>
          </Header>
        ) : (
          <Header
            style={{
              backgroundColor: "#2e2e2e",
              padding: "0px",
            }}
          >
            <Navigation screens={screens} pathname={pathname} />
          </Header>
        )}
        <Layout
          className="content-container"
          style={{
            minHeight:
              screens.xl && (pathname === "/" || pathname === "/register")
                ? "calc(100vh - 289px)"
                : "calc(100vh - 64px)",
            justifyContent: "space-between",
          }}
        >
          {pathname === "/recover" ||
          pathname === "/reset-password" ||
          pathname === "/privacy-policy" ? null : (
            <Sidebar screens={screens} pathname={pathname} />
          )}

          {routes}
        </Layout>
        <Footer
          className="footer"
          style={{ color: "#fff", backgroundColor: "#2e2e2e" }}
        >
          <FooterNavigation />
        </Footer>
      </Layout>
    );
  }

  return <div>{data}</div>;
}

export default App;
