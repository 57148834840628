import React from "react";
import MenuCardHOC from "../../components/menu/MenuCardHOC";

import { Input, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { submitCustomerSupportRequest } from "../../store/actions/form";
import Spinner from "../../components/UI/Spinner";
import { Select } from "antd";

const { Option } = Select;

function Contact() {
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = React.useState(false);
  const [subject, setSubject] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [connectedToADeal, setConnectedToADeal] = React.useState(false);
  const [dealId, setDealId] = React.useState("-");

  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const token = useSelector((state) => state.auth.token);
  const offers = useSelector((state) => state.offers.offers);

  const loading = useSelector((state) => state.form.loading);

  function handleChange(value) {
    setConnectedToADeal(value);
    if (!value) {
      setDealId("-");
    }
  }
  const handleDealChange = (value) => {
    setDealId(value);
  };

  const onSubmit = () => {
    setSubmitted(true);
    dispatch(
      submitCustomerSupportRequest(subject, question, dealId, checkbox, token)
    );
  };
  const onChange = (e) => {
    if (e.target.name === "subject") {
      setSubject(e.target.value);
    } else if (e.target.name === "question") {
      setQuestion(e.target.value);
    }
  };
  let form;
  if (isAuth) {
    form = (
      <Form layout="vertical" onFinish={onSubmit} style={{ fontSize: "16px" }}>
        <div style={{ textAlign: "center" }}>
          Fill out our form and we will contact you!
        </div>
        {offers.length !== 0 ? (
          <div
            className="flex-row"
            style={{ marginBottom: "30px", marginTop: "30px" }}
          >
            <div
              style={{
                textAlign: "left",
                alignSelf: "center",
                boxSizing: "border-box",
                marginRight: "20px",
              }}
            >
              Is your question connected to a specific deal?
            </div>
            <Select
              defaultValue="No"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value={false}>No</Option>
              <Option value={true}>Yes</Option>
            </Select>
          </div>
        ) : null}
        {connectedToADeal ? (
          <div className="flex-row" style={{ marginBottom: "30px" }}>
            <div
              style={{
                textAlign: "left",
                alignSelf: "center",
                boxSizing: "border-box",
                marginRight: "20px",
              }}
            >
              Select a deal your question is connected to:
            </div>
            <Select
              defaultValue=""
              style={{ width: 120 }}
              onChange={handleDealChange}
            >
              {offers.map((offer) => {
                return (
                  <Option key={offer.Id} value={offer.Id}>
                    {offer.Name}
                  </Option>
                );
              })}
            </Select>
          </div>
        ) : null}
        <div
          className="flex-row"
          style={{ marginBottom: "30px", marginTop: "30px" }}
        >
          <div
            style={{
              textAlign: "left",
              minWidth: "100px",
              maxWidth: "180px",
              alignSelf: "normal",
              boxSizing: "border-box",
            }}
          >
            Subject
          </div>
          <div className="deal-details-offer-field" style={{ width: "100%" }}>
            {submitted && !loading ? (
              subject
            ) : (
              <Input
                name="subject"
                autoComplete="off"
                style={{ width: "100%" }}
                onChange={onChange}
                size="large"
                value={subject}
              />
            )}
          </div>
        </div>
        <div className="flex-row">
          <div
            style={{
              textAlign: "left",
              minWidth: "100px",
              maxWidth: "180px",
              alignSelf: "normal",
              boxSizing: "border-box",
            }}
          >
            Message
          </div>
          <div className="deal-details-offer-field" style={{ width: "100%" }}>
            {submitted && !loading ? (
              question
            ) : (
              <TextArea
                rows={5}
                autoComplete="off"
                name="question"
                style={{}}
                onChange={onChange}
                size="large"
                value={question}
              />
            )}
          </div>
        </div>
        {submitted && !loading ? null : (
          <div style={{ margin: "10px 20px" }}>
            <input
              onChange={() => setCheckbox(!checkbox)}
              type="checkbox"
              checked={checkbox}
            />
            <label style={{ fontSize: "16px", marginLeft: "10px" }}>
              Send me a copy
            </label>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {loading ? (
            <Spinner
              style={{
                height: "24px",
                width: "24px",
                color: "#bfbfbf",
                fontSize: "24px",
                marginTop: "10px",
              }}
            />
          ) : submitted ? (
            <div
              className="pending-deals-section"
              style={{
                backgroundColor: "#b7ff96",
                width: "100%",
                textAlign: "center",
                display: "block",
                marginTop: "30px",
              }}
            >
              Your request has been submitted successfully!
            </div>
          ) : (
            <Button
              onClick={onSubmit}
              disabled={connectedToADeal && dealId === "-"}
              style={{
                display: "inline-block",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    );
  }
  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <span
          style={{ textDecoration: "underline" }}
          className="dashboard-title"
        >
          Contact Us
        </span>
      </div>
      <div
        style={{ marginTop: "5px", display: "flex", justifyContent: "center" }}
      >
        <h2>WE WANT TO HEAR FROM YOU</h2>
      </div>
      <div style={{ marginTop: "30px", fontSize: "16px" }}>
        <p>
          Whether you have a question about an offer, the process, our digital
          platform, or anything else, our team is readily available to answer
          any questions you may have.
        </p>
        <p>
          Don't have an offer? You can request one via the digital dashboard on
          our homepage or call us toll-free to speak with a live funding
          specialist.
        </p>
        <p>
          Want to give us feedback? We encourage it with a view to continuous
          improvement.
        </p>
        <p>
          Call us at{" "}
          <span style={{ color: "#1085b5", textDecoration: "underline" }}>
            (844) 989-3171
          </span>
        </p>
        <p>
          Email us at{" "}
          <a
            href={"mailto:info@glsfunding.com"}
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ color: "#1085b5", textDecoration: "underline" }}>
              info@glsfunding.com
            </span>
          </a>
        </p>
      </div>
      {isAuth ? <div className="contact-form">{form}</div> : null}
    </div>
  );
}

export default MenuCardHOC(Contact);
