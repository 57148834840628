import React, { Component } from "react";
import { connect } from "react-redux";
import { openMenuItem } from "../../../store/actions/menu";
import { menuList, loggedMenuList } from "../../menu/data/menulist";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { StyledMenu } from "../../styledComponents/styledComponents";

class MenuList extends Component {
  state = {
    pathname: this.props.pathname,
  };

  openMenuItem = (e) => {
    if (!this.props.screens.xl) {
      this.props.openMenuItem({
        menuItem: e.item.props.value,
        menuIsOpen: false,
      });
    } else {
      this.props.openMenuItem({
        menuItem: e.item.props.value,
        menuIsOpen: true,
      });
    }
  };

  render() {
    let areTherePendingOffers = this.props.offers.some(
      (offer) => offer.Status__c === "Offer Out"
    );

    let notificationDiv = (
      <span className="newNotificationDiv">
        <span className="newNotificationSpan2">
          <span className="newNotificationSpan3">
            {this.props.newNotifications.length}
          </span>
        </span>
      </span>
    );

    let pendingDealsMenuAlert = (
      <span className="newNotificationDiv">
        <span className="newNotificationSpan2">
          <span className="newNotificationSpan3">{"!"}</span>
        </span>
      </span>
    );

    let styledMenu = null;
    if (this.props.isLoading === false) {
      styledMenu = (
        <StyledMenu
          style={{ boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.35)" }}
          screens={this.props.screens}
          pathname={this.props.pathname}
        >
          {this.props.isAuth
            ? loggedMenuList.map(({ name, route }) => (
                <Menu.Item
                  key={name}
                  value={name}
                  className="loggedMenu"
                  onClick={this.openMenuItem}
                  title=""
                  style={{
                    textAlign: "center",
                    backgroundColor:
                      name === "My Dashboard"
                        ? "rgb(46, 46, 46)"
                        : this.props.pathname.split("/")[1] === route
                        ? "#ffee96"
                        : null,
                  }}
                >
                  <Link to={"/" + route}>{name}</Link>
                  {name === "Notifications" &&
                  this.props.newNotifications.length !== 0
                    ? notificationDiv
                    : null}
                  {name === "Deals" && areTherePendingOffers
                    ? pendingDealsMenuAlert
                    : null}
                </Menu.Item>
              ))
            : null}
          {this.props.isAuth ? <div style={{ height: "50px" }}></div> : null}
          {menuList.map(({ name, route }) => (
            <Menu.Item
              key={name}
              value={name}
              onClick={this.openMenuItem}
              title=""
              style={{
                textAlign: "center",
                backgroundColor:
                  this.props.pathname === "/" + route ? "#ffee96" : null,
              }}
            >
              <Link to={"/" + route}>{name}</Link>
            </Menu.Item>
          ))}
        </StyledMenu>
      );
    }
    return <>{styledMenu}</>;
  }
}

const mapDispatchToProps = {
  openMenuItem,
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticated,
    newNotifications: state.notifications.newNotifications,
    isLoading: state.auth.isLoading,
    offers: state.offers.offers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
