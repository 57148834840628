import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";

function helpMenu() {
  return (
    <Menu>
      <Menu.Item>Call Now</Menu.Item>
      <Menu.Item>
        <Link to="/faq">FAQ</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/contact">Contact</Link>
      </Menu.Item>
    </Menu>
  );
}

function HelpDropdown() {
  return (
    <Dropdown overlay={helpMenu} trigger={["click"]}>
      <span>HELP</span>
    </Dropdown>
  );
}

export default HelpDropdown;
