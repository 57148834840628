import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { FiExternalLink } from "react-icons/fi";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationRead } from "../../store/actions/notifications";

const Notification = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const [visible, setVisible] = React.useState(false);

  const handleVisibilityAndStatus = () => {
    setVisible(!visible);
    if (props.notification.read_datetime === null) {
      dispatch(notificationRead(token, props.notification.id));
    }
  };
  return (
    <>
      <div className="notification">
        <div className="flex-row">
          <div
            className={
              props.notification.read_datetime
                ? "notification-table-date"
                : "notification-table-date bold"
            }
          >
            {new Date(props.notification.creation_date).toLocaleDateString(
              "en-US"
            )}
          </div>
          <div
            onClick={handleVisibilityAndStatus} // here change to expand
            className={
              props.notification.read_datetime
                ? "notification-table-dealId"
                : "notification-table-dealId bold"
            }
          >
            {props.name}
          </div>
          <div
            onClick={handleVisibilityAndStatus} // change to expand
            className={
              props.notification.read_datetime
                ? "notification-table-subject"
                : "notification-table-subject bold"
            }
          >
            {props.notification.subject}
          </div>
          {!visible ? (
            <DownCircleOutlined
              onClick={handleVisibilityAndStatus}
              className="notification-icon"
            />
          ) : (
            <UpCircleOutlined
              onClick={handleVisibilityAndStatus}
              className="notification-icon"
            />
          )}
          {props.name === "-" || props.name === "" ? null : (
            <FiExternalLink
              onClick={props.redirectHandler.bind(this, props.notification)}
              className="notification-icon"
            />
          )}
        </div>
        {visible && (
          <div className="notification-message">
            {props.notification.message}
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
