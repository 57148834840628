import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
// import { UserOutlined } from "@ant-design/icons";
import { VscAccount } from "react-icons/vsc";
import { logout } from "../../store/actions/auth";

const { Content } = Layout;

function DashboardCardHOC(WrappedComponent) {
  return function ChildComponent(props) {
    const userName = useSelector((state) => state.auth.userFullName);
    const dispatch = useDispatch();
    let history = useHistory();

    return (
      <Content className="dashboard-sub">
        <div className="dashboard-profile-section">
          <VscAccount
            onClick={() => history.push("/profile")}
            style={{
              fontSize: "50px",
              paddingBottom: "0px",
              cursor: "pointer",
              alignSelf: "center",
              color: "#898989",
            }}
          />
          <div
            style={{
              marginRight: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "7px 0px",
            }}
          >
            <div>{userName}</div>
            <div
              className="logoutButton"
              onClick={() => {
                dispatch(logout());
              }}
            >
              Sign Out
            </div>
          </div>
        </div>
        <WrappedComponent />
      </Content>
    );
  };
}

export default DashboardCardHOC;
