import React from "react";
import { CloudDownloadOutlined, DeleteFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteFile, downloadFile } from "../../../../../store/actions/uploads";

const AlreadyUploaded = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const downloadDocument = (doc) => {
    dispatch(downloadFile(doc, token));
  };

  const deleteDocument = (doc) => {
    dispatch(deleteFile(doc, token));
  };

  return (
    <>
      {props.uploadedDocuments.map((upload) => {
        return (
          <div
            key={upload.download_url}
            className="uploadedFile flex-row-always spread"
          >
            <div
              style={{
                flex: 1,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <span>
                &bull;{" "}
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {upload.file_name}
                </span>
              </span>
            </div>
            <div>
              <DeleteFilled
                className="icon action-icon"
                onClick={deleteDocument.bind(this, upload)}
              />
              <CloudDownloadOutlined
                className="icon action-icon"
                onClick={downloadDocument.bind(this, upload)}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AlreadyUploaded;
