import React from "react";
import { Row, Col } from "antd";
import FileProtectOutlined from "@ant-design/icons/FileProtectOutlined";
import { useHistory } from "react-router-dom";

function FooterNavigation() {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const history = useHistory();
  return (
    <nav>
      <Row gutter={4} justify="space-around">
        <Col style={{ textAlign: "center" }}>(844) 989-3171</Col>
        <Col style={{ textAlign: "center" }}>
          18205 Biscayne Blvd. | Suite 2218 | Aventura, FL 33160
        </Col>
        <Col style={{ textAlign: "center" }}>
          info@glsfunding.com{" "}
          <FileProtectOutlined
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={() => {
              history.push("/privacy-policy");
            }}
            style={{ marginLeft: "10px" }}
          />
          {showTooltip && (
            <div className="tooltip-privacy">
              <div>Privacy Policy</div>
            </div>
          )}
        </Col>
      </Row>
    </nav>
  );
}

export default FooterNavigation;
