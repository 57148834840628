import React from "react";
import MenuCardHOC from "../../components/menu/MenuCardHOC";
import { useHistory } from "react-router-dom";

import glsimage from "../../assets/glsimage.png";

function HowItWorks() {
  const bold = { fontWeight: "bold" };
  const history = useHistory();
  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <span
          style={{ textDecoration: "underline" }}
          className="dashboard-title"
        >
          How It Works
        </span>
      </div>

      <div
        style={{ marginTop: "5px", display: "flex", justifyContent: "center" }}
      >
        <h2>4 Simple Steps</h2>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2>YOUR MONEY IS JUST A CLICK AWAY</h2>
      </div>

      <div style={{ marginTop: "30px", fontSize: "16px" }}>
        <p>
          Our new online platform was designed to make the settlement transfer
          process quicker, simpler, and safer. Just follow the steps below and
          leave the rest to us.
        </p>
        <p>
          We have integrated security technology guaranteeing that any
          information you provide is safe and private - and best of all,
          documents can be conveniently uploaded online from the comfort of your
          own home, even from your phone.
        </p>
        <p>
          Here at Gold Leaf, our first offer is the best offer. We know how
          valuable your time is, so why waste it negotiating your purchase
          price?
        </p>
        <div style={{ marginBottom: "30px" }}>
          <img src={glsimage} alt="proba" width="100%" height="auto" />
        </div>
        <p>The steps to get started are straightforward:</p>
        <div style={{ marginLeft: "50px" }}>
          <ol>
            <li>
              <span style={bold}>Review and accept</span> your offer on our
              online portal with your offer ID and date of birth. If you do not
              already have an offer, don’t sweat it. You can request one through
              the portal and get an offer on the same day.
            </li>
            <li>
              <span style={bold}>Create your profile.</span> Once you create and
              log in to your profile, you will have access to your digital
              dashboard where all documents can be uploaded, reviewed, and
              signed. You can also request a cash advance on your digital
              dashboard. If needed, our live support team is readily available
              to walk you through what paperwork you need to submit or answer
              any other questions you may have.
            </li>
            <li>
              <span style={bold}>Leave the rest to us.</span> You will receive
              update notifications throughout the transfer process on the
              digital dashboard unique to your profile; cash advances, court
              dates, hearing times, and transfer approvals.
            </li>
            <li>
              <span style={bold}>Get approved and get paid.</span>
            </li>
          </ol>
        </div>
        <p>
          <span style={bold}>
            Contact our live support team for any further questions regarding
            our new digital platform.
            <br />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/contact");
            }}
          >
            (844) 989-3171
          </span>
        </p>
      </div>
    </div>
  );
}

export default MenuCardHOC(HowItWorks);
