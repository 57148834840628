export const menuList = [
  { name: "About GLS", route: "about", component: "AboutGLS" },
  { name: "How It Works", route: "how-it-works", component: "HowItWorks" },
  { name: "Why Choose Us", route: "why-choose-us", component: "WhyChooseUs" },
  { name: "FAQ", route: "faq", component: "FAQ" },
  { name: "Contact", route: "contact", component: "Contact" },
];

export const loggedMenuList = [
  { name: "My Dashboard", route: "mydashboard"},
  { name: "Deals", route: "deals"},
  { name: "Notifications", route: "notifications" },
  { name: "Profile", route: "profile" },
]