import React from "react";

const DealDetailRow = (props) => {
  const outterDivClassName = ["flex-row deal-details-row"];
  if (props.spread) {
    outterDivClassName.push("spread");
  }
  let field = null;
  if (props.property === "Contact Person:") {
    field = (
      <a
        href={
          "mailto:" +
          props.offer.Contact_person.Email +
          "?subject=" +
          props.offer.Id
        }
        target="_blank"
        rel="noreferrer"
      >
        <span className="contact-person">{props.offerField}</span>
      </a>
    );
  } else {
    field = props.offerField;
  }
  return (
    <div className={outterDivClassName.join(" ")} style={{ ...props.style }}>
      <div className="deal-details-property">{props.property}</div>
      <div className="deal-details-offer-field">{field}</div>
    </div>
  );
};

export default DealDetailRow;
