import {
  MENU_OPENED,
  MENU_CLOSED,
  SCREEN_IS_DESKTOP,
  SCREEN_IS_MOBILE,
  MENU_ITEM_SELECTED,
} from "./types";

export const openMenu = () => ({ type: MENU_OPENED });
export const closeMenu = () => ({ type: MENU_CLOSED });
export const openMenuOnDesktop = () => ({ type: SCREEN_IS_DESKTOP });
export const openMenuOnMobile = () => ({ type: SCREEN_IS_MOBILE });
export const openMenuItem = (payload) => ({
  type: MENU_ITEM_SELECTED,
  payload,
});
