import styled from "styled-components";
import { Row, Layout, Menu } from "antd";
import {
  ExclamationCircleFilled,
  CheckCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

export const StyledRow = styled(Row)`
  margin: 8px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.35);
  background-color: #fff;
  padding: 12px;
`;

export const StyledExclamationCircledFilled = styled(ExclamationCircleFilled)`
  font-size: 24px;
  color: #1085b5;
`;

export const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  font-size: 24px;
  color: #1085b5;
`;

export const StyledUploadOutlined = styled(UploadOutlined)`
  font-size: 12px;
  padding: 6px;
  background-color: #1085b5;
  color: #fff;
  border-radius: 50%;
`;

export const StyledSider = styled(Sider)`
  color: #fff;
  background: none;
`;

export const StyledMenu = styled(Menu).attrs((props) => ({
  size:
    props.screens.xl && props.pathname !== "/" && props.pathname !== "/register"
      ? "172px"
      : "0px",
}))`
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.35);
  border-right: none;
  margin-right: 8px;
  margin-top: ${(props) => props.size};

  a:hover {
    color: #595959;
  }

  li:hover {
    background-color: #ffee96;
  }
`;
