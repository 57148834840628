import axios from "axios";

import {
  GET_OFFERS,
  GET_OFFER,
  OFFERS_LOADING,
  GET_OFFER_ERROR,
  CLEAR_OFFER_ERROR,
  PENDING_OFFER_ACCEPTED,
  RESET_PENDING_OFFER_ACCEPTED,
  OFFER_DENIED,
  PENDING_OFFER_ACCEPTING,
  REQUEST_CASH,
} from "./types";
import { BASE_URL } from "../../constants/constants";

export const getOffer = (token, deal_id) => (dispatch) => {
  dispatch({ type: OFFERS_LOADING });
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios
    .get(BASE_URL + "api/v1/users/me/deals/" + deal_id, config)
    .then((res) => {
      dispatch({ type: GET_OFFER, response: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_OFFER_ERROR });
    });
};

export const getOffers = (token) => (dispatch) => {
  dispatch({ type: OFFERS_LOADING });
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios
    .get(BASE_URL + "api/v1/users/me/deals/", config)
    .then((res) => {
      dispatch({
        type: GET_OFFERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const offerAccepted = (offer_id, token) => (dispatch) => {
  // const token = localStorage.getItem("token");
  const data = {};
  dispatch({ type: PENDING_OFFER_ACCEPTING });
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios
    .put(
      BASE_URL + "api/v1/users/me/deals/" + offer_id + "?action=accept",
      data,
      config
    )
    .then((response) => {
      dispatch({
        type: PENDING_OFFER_ACCEPTED,
        offer_id: offer_id,
        full_offer: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const requestCash =
  (status, amount, reason, dealId, token) => (dispatch) => {
    const data = {
      Cash_Advance_Amount_Requested__c: parseInt(amount),
      Cash_Advance_Reason__c: reason,
      Cash_Advance_Status__c: "Requested",
    };
    const config = {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    if (!status) {
      axios
        .post(
          BASE_URL + "api/v1/users/me/deals/" + dealId + "/cash_advance/",
          data,
          config
        )
        .then((response) => {
          dispatch({
            type: REQUEST_CASH,
            offerId: dealId,
            amount: amount,
            reason: reason,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .put(
          BASE_URL + "api/v1/users/me/deals/" + dealId + "/cash_advance/",
          data,
          config
        )
        .then((response) => {
          dispatch({
            type: REQUEST_CASH,
            offerId: dealId,
            amount: amount,
            reason: reason,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

export const offerDeclined =
  (offer_id, token, numberOfPendingOffers, denyReason) => (dispatch) => {
    // const token = localStorage.getItem("token");
    dispatch({ type: PENDING_OFFER_ACCEPTING }); // loading
    const data = {
      deny_reason: denyReason,
    };
    const config = {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .put(
        BASE_URL + "api/v1/users/me/deals/" + offer_id + "?action=deny",
        data,
        config
      )
      .then((response) => {
        dispatch({
          type: OFFER_DENIED,
          offer_id: offer_id,
          numberOfPendingOffers: numberOfPendingOffers,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const clearOfferError = () => (dispatch) => {
  dispatch({ type: CLEAR_OFFER_ERROR });
};

export const resetPendingOfferAccepted = () => (dispatch) => {
  dispatch({ type: RESET_PENDING_OFFER_ACCEPTED });
};
