import axios from "axios";
import {
  ACCEPTED_OFFER_ID,
  REJECTED_OFFER_ID,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  REGISTER_START,
} from "./types";

import { BASE_URL } from "../../constants/constants";
import { getOffers } from "./offers";

export const checkOfferId = (offerId, dob) => {
  return (dispatch) => {
    const config = {
      headers: {
        accept: "application/json",
      },
    };
    // Request Body
    const queryParams = "?offer_id=" + offerId + "&dob=" + dob;
    const data = "";

    axios
      .post(BASE_URL + "api/v1/users/verify" + queryParams, data, config)
      .then((res) => {
        dispatch({
          type: ACCEPTED_OFFER_ID,
          offerId: offerId,
          dob: dob,
          fullName: res.data.full_name,
        });
      })
      .catch((err) => {
        dispatch({
          type: REJECTED_OFFER_ID,
        });
      });
  };
};

export const registerStart = (
  fullName,
  dob,
  email,
  password,
  phoneNumber,
  validOfferId,
  timezone
) => {
  return (dispatch) => {
    dispatch({ type: REGISTER_START });
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      password: password,
      email: email,
      full_name: fullName,
      offer_id: validOfferId,
      date_of_birth: dob,
      phone: phoneNumber,
      timezone: timezone,
    };

    axios
      .post(BASE_URL + "api/v1/users/register", requestBody, config)
      .then((res) => {
        const config2 = {
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        // Request Body
        const formBody2 =
          "grant_type=&username=" +
          email +
          "&password=" +
          password +
          "&scope=&client_id=&client_secret=";

        axios
          .post(BASE_URL + "api/v1/login/access-token", formBody2, config2)
          .then((response) => {
            localStorage.setItem("token", response.data.access_token);
            dispatch(getOffers(response.data.access_token));
            dispatch({
              type: REGISTER_SUCCESS,
              token: response.data.access_token,
              response: res.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_FAILED,
          message: error.response.data.detail,
        });
      });
  };
};
