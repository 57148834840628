import {
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  TEST_TOKEN_FAIL,
  TEST_TOKEN_SUCCESS,
  REGISTER_SUCCESS,
  LOGIN_FAIL,
  UPDATE_ME,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: true,
  isLoading: true,
  userEmail: null,
  userFullName: null,
  dateOfBirth: null, //dateOfBirth is stored as YYYY-MM-DD
  userPhoneNumber: null,
  userIsSuperuser: null,
  userId: null,
  userSalesforceId: null,
  userIsActive: null,
  authErrorMessage: "",
  sendNotificationsViaEmail: null,
  subscribeToNewsletter: null,
  isDesktopAndHasWebcam: null,
  timezone: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        userEmail: action.response.email,
        userFullName: action.response.full_name,
        userPhoneNumber: action.response.phone,
        userIsSuperuser: action.response.is_superuser,
        userId: action.response.id,
        userSalesforceId: action.response.salesforce_id,
        userIsActive: action.response.is_active,
        isLoading: false,
        dateOfBirth: action.response.date_of_birth,
        authErrorMessage: "",
        sendNotificationsViaEmail: action.response.send_notifications_via_email,
        subscribeToNewsletter: action.response.subscribe_to_newsletter,
        timezone: action.response.timezone,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        authErrorMessage: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        userEmail: null,
        userFullName: null,
        userPhoneNumber: null,
        userIsSuperuser: null,
        userId: null,
        userSalesforceId: null,
        userIsActive: null,
        sendNotificationsViaEmail: null,
        subscribeToNewsletter: null,
        dateOfBirth: null,
        isDesktopAndHasWebcam: null,
      };
    case TEST_TOKEN_FAIL:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        userEmail: null,
        userFullName: null,
        userPhoneNumber: null,
        userIsSuperuser: null,
        userId: null,
        userSalesforceId: null,
        userIsActive: null,
        sendNotificationsViaEmail: null,
        subscribeToNewsletter: null,
        dateOfBirth: null,
        isDesktopAndHasWebcam: null,
      };
    case TEST_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        userEmail: action.response.email,
        userFullName: action.response.full_name,
        userPhoneNumber: action.response.phone,
        userIsSuperuser: action.response.is_superuser,
        userId: action.response.id,
        userSalesforceId: action.response.salesforce_id,
        userIsActive: action.response.is_active,
        isAuthenticated: true,
        isLoading: false,
        sendNotificationsViaEmail: action.response.send_notifications_via_email,
        subscribeToNewsletter: action.response.subscribe_to_newsletter,
        dateOfBirth: action.response.date_of_birth,
        timezone: action.response.timezone,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        token: action.token,
        userEmail: action.response.email,
        userFullName: action.response.full_name,
        userPhoneNumber: action.response.phone,
        userIsSuperuser: action.response.is_superuser,
        userId: action.response.id,
        userSalesforceId: action.response.salesforce_id,
        userIsActive: action.response.is_active,
        isLoading: false,
        isAuthenticated: true,
        sendNotificationsViaEmail: true,
        subscribeToNewsletter: false,
        dateOfBirth: action.response.date_of_birth,
        timezone: action.response.timezone,
      };
    case UPDATE_ME:
      return {
        ...state,
        userEmail: action.response.email,
        userFullName: action.response.full_name,
        userPhoneNumber: action.response.phone,
        userIsSuperuser: action.response.is_superuser,
        userId: action.response.id,
        userSalesforceId: action.response.salesforce_id,
        userIsActive: action.response.is_active,
        isAuthenticated: true,
        // isLoading: false,
        sendNotificationsViaEmail: action.response.send_notifications_via_email,
        subscribeToNewsletter: action.response.subscribe_to_newsletter,
        dateOfBirth: action.response.date_of_birth,
        timezone: action.response.timezone,
      };
    default:
      return state;
  }
};

export default reducer;
