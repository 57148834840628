import React from "react";
import LoggedOutLinks from "../navLinks/LoggedOutLinks";

function Navigation({ screens, pathname }) {
  return (
    <nav className="desktop-links">
      <LoggedOutLinks screens={screens} pathname={pathname} />
    </nav>
  );
}

export default Navigation;
