import {
  CLEAR_OFFER_ERROR,
  GET_OFFER,
  GET_OFFERS,
  GET_OFFER_ERROR,
  LOGOUT_SUCCESS,
  OFFERS_LOADING,
  OFFER_DENIED,
  PENDING_OFFER_ACCEPTED,
  PENDING_OFFER_ACCEPTING,
  REQUEST_CASH,
  RESET_PENDING_OFFER_ACCEPTED,
} from "../actions/types";

const initialState = {
  offers: [],
  deadOffers: [],
  offer: null,
  loading: null,
  error: null,
  redirectTo: null,
  pendingOfferAccepting: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFER:
      return {
        ...state,
        offer: action.response,
        loading: null,
      };
    case GET_OFFER_ERROR:
      return {
        ...state,
        error: true,
        loading: null,
      };
    case CLEAR_OFFER_ERROR:
      return {
        ...state,
        error: null,
      };
    case GET_OFFERS:
      return {
        ...state,
        offers: action.payload.filter((offer) => offer.Status__c !== "Dead"),
        deadOffers: action.payload.filter(
          (offer) => offer.Status__c === "Dead"
        ),
        loading: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case PENDING_OFFER_ACCEPTED:
      const pendingOffersBefore = state.offers.filter(
        (offer) => offer.Status__c === "Offer Out"
      );
      const oldOffersArray = state.offers.filter(
        (offer) => offer.Status__c !== "Offer Out"
      );
      return {
        ...state,
        redirectTo: "/deals/" + action.offer_id,
        deadOffers: pendingOffersBefore.filter(
          (offer) => offer.Id !== action.offer_id
        ),
        offers: oldOffersArray.concat(action.full_offer),
        pendingOfferAccepting: null,
        offer: action.full_offer,
      };
    case OFFER_DENIED:
      const deniedOffer = state.offers.find(
        (offer) => offer.Id === action.offer_id
      );
      console.log(deniedOffer);
      deniedOffer.Status__c = "Dead";
      return {
        ...state,
        offers: state.offers.filter((offer) => offer.Id !== action.offer_id),
        deadOffers: state.deadOffers.concat(deniedOffer),
        redirectTo: action.numberOfPendingOffers === 1 ? "/deals" : null,
        pendingOfferAccepting: null,
      };
    case PENDING_OFFER_ACCEPTING:
      return {
        ...state,
        pendingOfferAccepting: true,
      };
    case RESET_PENDING_OFFER_ACCEPTED:
      return {
        ...state,
        redirectTo: null,
      };
    case REQUEST_CASH:
      const changedOffer = state.offers.find(
        (offer) => (offer.Id = action.offerId)
      );
      changedOffer.Cash_Advance_Reason__c = action.reason;
      changedOffer.Cash_Advance_Amount_Requested__c = action.amount.toString();
      changedOffer.Cash_Advance_Status__c = "Requested";
      return {
        ...state,
        offers: state.offers,
        offer: changedOffer,
      };
    default:
      return state;
  }
};

export default reducer;
