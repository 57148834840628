import { Input, Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { recoverPassword } from "../store/actions/form";
import Recaptcha from "react-google-invisible-recaptcha";
import { FORM_RESET } from "../store/actions/types";

const Recover = () => {
  const captchaRef = React.useRef();
  const dispatch = useDispatch();
  const responseMessage = useSelector((state) => state.form.formMessage);
  const responseSuccess = useSelector((state) => state.form.formSuccess);
  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const onChange = (e) => {
    if (showError) {
      validate(e.target.value);
    }
    setEmail(e.target.value);
  };

  React.useEffect(
    () => () => {
      dispatch({ type: FORM_RESET });
    },
    [dispatch]
  );

  const validate = (value = false) => {
    if (!value) {
      value = email;
    }
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    let error = pattern.test(value)
      ? false
      : "Please enter a valid email address";
    if (error) {
      if (!showError) {
        setShowError(true);
      }
      setErrorMessage(error);
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  };

  const submitForm = () => {
    if (!validate()) {
      return;
    }
    captchaRef.current
      .execute()
      .then((recaptchaToken) => {
        dispatch(recoverPassword(email, recaptchaToken));
      })
      .catch((err) => console.log(err));
  };
  return (
    <div
      style={{
        padding: "40px 40px",
        margin: "100px auto",
        border: "1px solid #bfbfbf",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        width: "500px",
        maxWidth: "90%",
      }}
    >
      <h2 style={{ fontWeight: "bold" }}>Forgot your password?</h2>
      <p>
        Enter the email address that you registered with us and we will send you
        further instructions.
      </p>
      <Input
        name="email"
        type="email"
        disabled={responseSuccess}
        placeholder="Email"
        autoComplete="off"
        style={{ width: "100%", display: "block" }}
        onChange={onChange}
        size="large"
        value={email}
      />
      <div
        style={{
          height: "20px",
          color: "red",
          fontSize: "13px",
          lineHeight: "20px",
          margin: "0 0 10px 10px",
          textAlign: "left",
        }}
      >
        {errorMessage}
      </div>
      <Recaptcha
        ref={(ref) => (captchaRef.current = ref)}
        sitekey="6Le8F9kdAAAAAD7kh021fhcRixEsLbEyQaTp1W4I"
      />
      {responseSuccess ? (
        <div
          style={{
            height: "20px",
            color: "green",
            fontSize: "13px",
            lineHeight: "20px",
            margin: "0 0 10px 10px",
            textAlign: "left",
          }}
        >
          {responseMessage}
        </div>
      ) : (
        <>
          <div
            style={{
              color: "red",
              fontSize: "13px",
              lineHeight: "20px",
              margin: "0 0 10px 10px",
              textAlign: "left",
            }}
          >
            {responseMessage}
          </div>
          <Button
            style={{ display: "block", margin: "auto" }}
            onClick={submitForm}
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default Recover;
