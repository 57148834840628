import {
  CUSTOMER_SUPPORT_REQUEST,
  CUSTOMER_SUPPORT_REQUEST_START,
  OPEN_OFFER_FORM,
  REQUEST_OFFER,
  REQUEST_OFFER_ERROR,
  FORM_LOADING,
  FORM_RESET,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../actions/types";

const initialState = {
  offerForm: false,
  loading: false,
  formSuccess: null,
  formMessage: null,
};

export default function reducer(state = initialState, action) {
  let message;
  switch (action.type) {
    case OPEN_OFFER_FORM:
      const offerForm = state.offerForm;
      return {
        ...state,
        offerForm: !offerForm, // toggle is for testing. Will eventually be set to true
      };
    case CUSTOMER_SUPPORT_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case FORM_LOADING:
      return {
        ...state,
        loading: true,
        formSuccess: null,
        formMessage: null,
      };
    case CUSTOMER_SUPPORT_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_OFFER:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        formSuccess: action.payload.success,
        formMessage: action.payload.message,
      };
    case REQUEST_OFFER_ERROR:
      return {
        ...state,
        loading: false,
        formSuccess: action.payload.success,
        formMessage: action.payload.message,
      };
    case FORM_RESET:
      return {
        ...initialState,
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: action.payload.success,
        formMessage: action.payload.message,
      };
    case RECOVER_PASSWORD_FAIL:
      message = "Request failed!";
      if (action.payload.data) {
        if (action.payload.data.detail) {
          message = action.payload.data.detail;
        }
      }
      return {
        ...state,
        loading: false,
        formSuccess: false,
        formMessage:
          action.payload.status === 404
            ? action.payload.data.detail
            : "Request failed",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
        formMessage: action.payload.message,
      };
    case RESET_PASSWORD_FAIL:
      message = " ";
      if (action.payload) {
        if (action.payload.data) {
          if (action.payload.data.detail) {
            message = action.payload.data.detail;
          }
        }
      }
      return {
        ...state,
        loading: false,
        formSuccess: false,
        formMessage: message,
      };

    default:
      return state;
  }
}
