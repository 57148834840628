import React from "react";

const PendingDealRow = (props) => {
  return (
    <div
      className={"pending-deal-row"}
      style={{
        flexDirection: props.flexColumn ? "column" : null,
      }}
    >
      <div
        className="pending-deal-property"
        style={{
          alignSelf: props.bigger ? "center" : null,
          fontSize: props.bigger ? "23px" : null,
        }}
      >
        {props.property}
      </div>
      <div
        className="pending-deal-value"
        style={{
          fontSize: props.bigger ? "23px" : null,
          fontWeight: props.bigger ? "520" : null,
          color: props.bigger ? "green" : null,
          textAlign: props.long ? "unset" : null,
          marginTop: props.long ? "5px" : null,
        }}
      >
        {props.value}
      </div>
    </div>
  );
};

export default PendingDealRow;
