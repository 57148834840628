import axios from "axios";

import {
  LOGIN_SUCCESS,
  USER_LOADING,
  LOGOUT_SUCCESS,
  TEST_TOKEN_FAIL,
  TEST_TOKEN_SUCCESS,
  LOGIN_FAIL,
  UPDATE_ME,
  USERS_ME,
} from "./types";
import { getOffers } from "./offers";

import { BASE_URL } from "../../constants/constants";

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    dispatch({ type: LOGOUT_SUCCESS });
  } else {
    // User Loading
    dispatch({ type: USER_LOADING });
    const config = {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const requestBody = "";
    axios
      .post(BASE_URL + "api/v1/login/test-token", requestBody, config)
      .then((res) => {
        dispatch({
          type: TEST_TOKEN_SUCCESS,
          response: res.data,
          token: token,
        });
        dispatch(getOffers(token));
      })
      .catch((err) => {
        localStorage.removeItem("token");
        dispatch({
          type: TEST_TOKEN_FAIL,
        });
      });
  }
};

// LOGIN USER
export const login = (email, password, rememberUser) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // Request Body
  const formBody =
    "grant_type=&username=" +
    email +
    "&password=" +
    password +
    "&scope=&client_id=&client_secret=";

  axios
    .post(BASE_URL + "api/v1/login/access-token", formBody, config)
    .then((res) => {
      if (true || rememberUser) {
        // should be if (rememberUser)
        localStorage.setItem("token", res.data.access_token);
      }
      const config2 = {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + res.data.access_token,
        },
      };
      axios
        .get(BASE_URL + "api/v1/users/me", config2)
        .then((response) => {
          dispatch({
            type: LOGIN_SUCCESS,
            token: res.data.access_token,
            response: response.data,
          });
          dispatch(getOffers(res.data.access_token));
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
      if (err.response.status === 400) {
        dispatch({
          type: LOGIN_FAIL,
          payload: err.response.data.detail,
        });
      }
    });
};

// LOGOUT USER
export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const updateMe = (props, token) => (dispatch) => {
  // const token = localStorage.getItem("token");
  const config = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const requestBody = {
    ...props,
  };
  axios
    .put(BASE_URL + "api/v1/users/me", requestBody, config)
    .then((response) => {
      dispatch({ type: UPDATE_ME, response: response.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const readUserMe = (token) => (dispatch) => {
  const config2 = {
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  axios
    .get(BASE_URL + "api/v1/users/me", config2)
    .then((response) => {
      dispatch({
        type: USERS_ME,
        response: response.data,
      });
    })
    .catch((err) => console.log(err));
};
