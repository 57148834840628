import React from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const DealOverview = (props) => {
  const userTimezone = useSelector((state) => state.auth.timezone);
  const displayDate = (date) => {
    if (!date) {
      return;
    }
    return moment(date).tz(userTimezone).format("L");
  };
  const history = useHistory();
  const goToDeal = () => {
    history.push("/deals/" + props.route);
  };
  return (
    <div onClick={goToDeal} className="deal-overview">
      <div className="deal-main-data">
        <Link to={"/deals/" + props.route} className="deal-name">
          {props.name}
        </Link>
        <div className="deal-date">
          Contract Sign Date: {displayDate(props.date)}
        </div>
        <div className="deal-status">Status: {props.status}</div>
      </div>
      <div className="deal-description">
        <p className="short-description">
          {props.description === null
            ? "Short Description here..."
            : props.description}
        </p>
      </div>
    </div>
  );
};

export default DealOverview;
