import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { openMenu } from "../../../store/actions/menu";
import { menuList } from "../../menu/data/menulist";
import HelpDropdown from "../../HelpDropdown";

class MobileLinks extends Component {
  openMenu = () => {
    this.props.dispatch(openMenu());
  };

  matchPath = (path) => {
    let link = menuList.find((item) => `/${item.route}` === path);
    return link;
  };

  getNavLink = () => {
    let link;
    link = this.matchPath(this.props.pathname);
    if (!link) {
      link = "Log In";
      return link;
    }
    return link.name;
  };
  render() {
    let navLink = this.getNavLink();
    return (
      <Row gutter={4} style={{ margin: "0 8px" }} justify="space-between">
        <Col xs={4} sm={4} md={2} lg={2} xl={2}>
          <ul>
            <li style={{ marginLeft: "20px" }} onClick={this.openMenu}>
              MENU
            </li>
          </ul>
        </Col>
        <Col xs={16} sm={16} md={20} lg={20} xl={20}>
          {this.props.isAuth ? null : (
            <ul>
              <li>| {navLink} |</li>
            </ul>
          )}
        </Col>
        <Col
          xs={4}
          sm={4}
          md={2}
          lg={2}
          xl={2}
          style={{ textAlign: "end", paddingRight: "20px" }}
        >
          <HelpDropdown />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticated,
    menuIsOpen: state.menu.menuIsOpen,
    menuItem: state.menu.menuItem,
  };
};

export default connect(mapStateToProps)(MobileLinks);
