import React from "react";
// import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { closeMenu } from "../../store/actions/menu";
import { motion } from "framer-motion";

// import { Grid, Row, Col } from "antd";

// const { useBreakpoint } = Grid;

function MenuCardHOC(WrappedComponent, data) {
  return function ChildComponent() {
    // const screens = useBreakpoint();
    // const menuIsOpen = useSelector((state) => state.menu.menuIsOpen);
    // const dispatch = useDispatch();
    // const closeMenuSidebar = () => {
    //   if (menuIsOpen && !screens.xl) {
    //     dispatch(closeMenu());
    //   }
    // };

    return (
      <motion.div
        animate={{ opacity: 1, x: 0 }}
        transition={{ ease: "easeInOut", duration: 0.7 }}
        initial={{ opacity: 0, x: "50%" }}
        exit={{ opacity: 0 }}
      >
        <WrappedComponent />
      </motion.div>
    );
  };
}

export default MenuCardHOC;
