import { Steps } from "antd";
import React from "react";

import DealDetailRow from "../../../../../components/accounts/DealDetailRow";
import { OFFER_STATUSES } from "../../../../../constants/constants";

const DealDetailsDeal = (props) => {
  const displayNumber = (number) => {
    if (!number) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const toDisplay = [
    { property: "Status:" },
    { property: "Payment Stream Description:" },
    { property: "Total Payments:" },
    { property: "Purchase Price Amount:" },
    { property: "Nominal Interest Rate:" },
    { property: "Contact Person:" },
  ];
  // const possibleStatuses = [
  //   "Offer Out",
  //   "Offer Accepted",
  //   "Disclosure Out",
  //   "Contract Sent",
  //   "Contract Back",
  //   "Sent to Counsel",
  //   "Court Date Set",
  //   "Set for Funding",
  //   "Funded",
  //   "Dead",
  // ];

  const statusIndex = OFFER_STATUSES.findIndex(
    (el) => el === props.offer.Status__c
  );

  let steps = (
    <div className="dealStatus">
      <Steps progressDot current={1} size="small" responsive="true">
        <Steps.Step title={OFFER_STATUSES[statusIndex - 1]} />
        <Steps.Step title={OFFER_STATUSES[statusIndex]} />
        {OFFER_STATUSES[statusIndex] === "Funded" ||
        OFFER_STATUSES[statusIndex] === "Dead" ? null : (
          <Steps.Step title={OFFER_STATUSES[statusIndex + 1]} />
        )}
      </Steps>
    </div>
  );
  let data = toDisplay.map((section) => {
    let offerField = null;
    switch (section.property) {
      case "Status:":
        offerField = props.offer.Status__c;
        break;
      case "Payment Stream Description:":
        offerField = props.offer.Payment_Stream_Description_Text__c
          ? props.offer.Payment_Stream_Description_Text__c
          : "-";
        break;
      case "Purchase Price Amount:":
        if (props.offer.Annuity) {
          if (props.offer.Annuity.Purchase_Price_Amount__c) {
            offerField =
              "$" + displayNumber(props.offer.Annuity.Purchase_Price_Amount__c);
          } else {
            offerField = "-";
          }
        } else if (props.offer.Purchase_Price_Amount__c) {
          offerField =
            "$" + displayNumber(props.offer.Purchase_Price_Amount__c);
        } else offerField = "-";
        break;
      case "Total Payments:":
        offerField = props.offer.Aggregate__c
          ? "$" + displayNumber(props.offer.Aggregate__c)
          : "-";
        break;
      case "Nominal Interest Rate:":
        offerField = props.offer.Nominal__c
          ? props.offer.Nominal__c + "%"
          : "-";
        break;
      case "Contact Person:":
        offerField =
          (props.offer.Contact_person.FirstName
            ? props.offer.Contact_person.FirstName
            : "") +
          " " +
          (props.offer.Contact_person.LastName
            ? props.offer.Contact_person.LastName
            : "");
        break;

      default:
        break;
    }
    return (
      <DealDetailRow
        key={section.property}
        property={section.property}
        offerField={offerField}
        offer={props.offer}
      />
    );
  });

  return (
    <>
      {steps}
      {data}
    </>
  );
};

export default DealDetailsDeal;
