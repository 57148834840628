import {
  DELETE_FILE,
  GET_OFFER,
  LOGOUT_SUCCESS,
  UPLOAD_DOCUMENT_LOADING,
  UPLOAD_DOCUMENT_SUCCESS,
} from "../actions/types";

const initialState = {
  uploadingDocuments: [],
  uploadedDocuments: [],
};

const uploads = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_LOADING:
      return {
        ...state,
        uploadingDocuments: state.uploadingDocuments.concat(action.doc_type),
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadingDocuments: state.uploadingDocuments.filter(
          (el) => el !== action.doc_type
        ),
        uploadedDocuments: state.uploadedDocuments.concat({
          document_type: action.doc_type,
          file_name: action.file.name,
          download_url: action.download_url,
        }),
      };
    case GET_OFFER:
      return {
        ...state,
        uploadedDocuments: action.response.uploaded_documents,
      };
    case DELETE_FILE:
      return {
        ...state,
        uploadedDocuments: state.uploadedDocuments.filter(
          (document) => document.download_url !== action.doc.download_url
        ),
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default uploads;
