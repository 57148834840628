import React from "react";
import { Button } from "antd";

const DeclineForm = (props) => {
  const options = [
    "Don't want to sell that many of my payments",
    "Offer is too low",
    "I don't need that much money",
    "Not interested in selling my payments",
    "Don't need cash right now",
  ];
  const [activeOption, setActiveOption] = React.useState(null);

  let optionsList = options.map((option) => {
    let classes = ["deny-option"];
    if (option === activeOption) {
      classes.push("deny-option-active");
    }
    return (
      <div
        key={option}
        onClick={() => optionEventHandler(option)}
        className={classes.join(" ")}
      >
        {option}
      </div>
    );
  });

  const optionEventHandler = (option) => {
    setActiveOption(option);
  };

  return (
    <div
      style={
        {
          // margin: "10px 20px",
        }
      }
    >
      <p className="deny-option-p">Help us help you!</p>
      <p className="deny-option-p">Why are you not interested at this time?</p>
      <div>
        <div
          style={{
            color: "#bfbfbf",
            textAlign: "center",
          }}
        >
          Select one option
        </div>
        <div>{optionsList}</div>
      </div>
      <div
        className="flex-row"
        style={{ justifyContent: "center", marginTop: "50px" }}
      >
        <Button
          className="decline-btn"
          style={{
            padding: "0px 30px",
            border: "1px solid #bfbfbf",
            fontSize: "16px",
            margin: "0px",
          }}
          disabled={!activeOption}
          onClick={props.clickHandler.bind(this, "decline", activeOption)}
        >
          DECLINE
        </Button>
      </div>
    </div>
  );
};

export default DeclineForm;
