import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestCash } from "../../store/actions/offers";

const { TextArea } = Input;

const CashRequestComponent = (props) => {
  const displayNumber = (number) => {
    if (!number) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const existingReason = useSelector(
    (state) => state.offers.offer.Cash_Advance_Reason__c
  );
  const existingAmount = parseInt(
    +useSelector((state) => state.offers.offer.Cash_Advance_Amount_Requested__c)
  );
  const existingStatus = useSelector(
    (state) => state.offers.offer.Cash_Advance_Status__c
  );
  const existingApproved = useSelector(
    (state) => state.offers.offer.Cash_Amount_Approved__c
  );

  React.useEffect(() => {
    setEditRequest(false);
    if (existingStatus) {
      setNewRequest(false);
    }
  }, [existingAmount, existingReason, existingStatus]);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [cashAmount, setCashAmount] = React.useState(existingAmount);
  const [reason, setReason] = React.useState(existingReason);
  const [editRequest, setEditRequest] = React.useState(false);
  const [newRequest, setNewRequest] = React.useState(!existingStatus);

  const onSubmit = (event) => {
    const cash = +cashAmount.replace(/[^0-9]/g, "");
    if (cash === existingAmount && reason === existingReason) {
      setEditRequest(false);
    }
    dispatch(
      requestCash(existingStatus, parseInt(cash), reason, props.deal_id, token)
    );
  };

  const onChange = (event) => {
    if (event.target.name === "cashAmount") {
      if (!isNaN(+event.target.value.replace(/[^0-9]/g, ""))) {
        setCashAmount(event.target.value.replace(/[^0-9]/g, ""));
      }
    } else if (event.target.name === "reason") {
      setReason(event.target.value);
    }
  };

  const cancelChanges = () => {
    setEditRequest(false);
    setReason(existingReason);
    setCashAmount(existingAmount);
    setNewRequest(!existingStatus);
  };

  const submitNewRequestHandler = () => {
    setNewRequest(true);
    setReason("");
    setCashAmount("");
  };

  let infoDiv = null;
  if (newRequest) {
    infoDiv = (
      <div style={{ marginBottom: "20px" }}>
        Here you can request cash in advance!
      </div>
    );
  } else if (existingStatus === "Requested" && !editRequest) {
    infoDiv = (
      <div className="pending-deals-section">
        <InfoCircleOutlined className="deals-info-icon" />
        Your request has been submitted. You can still edit it for some time.
      </div>
    );
  } else if (existingStatus === "In Review") {
    infoDiv = (
      <div className="pending-deals-section">
        <InfoCircleOutlined className="deals-info-icon" />
        We are reviewing your request.
      </div>
    );
  } else if (existingStatus === "Approved") {
    infoDiv = (
      <div
        className="pending-deals-section"
        style={{
          backgroundColor: "#b7ff96",
          color: "#595959",
        }}
      >
        <CheckCircleOutlined className="deals-info-icon" />
        Your request has been approved!
      </div>
    );
  } else if (existingStatus === "Denied") {
    infoDiv = (
      <div
        className="pending-deals-section"
        style={{
          backgroundColor: "#ff96b7",
          color: "black",
        }}
      >
        <CloseCircleOutlined className="deals-info-icon" />
        Your request has been denied! Please submit a new one!
      </div>
    );
  }

  return (
    <>
      {infoDiv}
      <Form layout="vertical" onFinish={onSubmit} style={{ fontSize: "16px" }}>
        <div className="flex-row" style={{ marginBottom: "30px" }}>
          <div
            className="deal-details-property"
            style={{ alignSelf: "center" }}
          >
            Cash amount{newRequest ? null : " requested"}
          </div>
          <div className="deal-details-offer-field" style={{ width: "100%" }}>
            {newRequest || editRequest ? (
              <Input
                autoComplete="off"
                name="cashAmount"
                prefix="$"
                style={{ width: "100%" }}
                onChange={onChange}
                size="large"
                value={displayNumber(cashAmount)}
              />
            ) : (
              "$ " + displayNumber(existingAmount)
            )}
          </div>
        </div>
        <div className="flex-row" style={{ marginBottom: "30px" }}>
          <div className="deal-details-property">Reason</div>
          <div className="deal-details-offer-field" style={{ width: "100%" }}>
            {newRequest || editRequest ? (
              <TextArea
                autoComplete="off"
                rows={5}
                name="reason"
                style={{}}
                onChange={onChange}
                size="large"
                value={reason}
              />
            ) : (
              existingReason
            )}
          </div>
        </div>
        {existingStatus === "Approved" && !newRequest ? (
          <div className="flex-row" style={{ marginBottom: "30px" }}>
            <div
              className="deal-details-property"
              style={{ alignSelf: "center" }}
            >
              Approved amount:
            </div>
            <div className="deal-details-offer-field" style={{ width: "100%" }}>
              {displayNumber(existingApproved)}
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {newRequest ? (
            <Button
              onClick={onSubmit}
              style={{
                display: "inline-block",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            >
              Request
            </Button>
          ) : editRequest ? (
            <>
              <Button
                onClick={onSubmit}
                style={{
                  display: "inline-block",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                }}
              >
                Save changes
              </Button>
              <Button
                onClick={cancelChanges}
                style={{
                  display: "inline-block",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                }}
              >
                Cancel
              </Button>
            </>
          ) : null}
          {!editRequest && existingStatus === "Requested" ? (
            <Button
              onClick={() => setEditRequest(true)}
              style={{
                display: "inline-block",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            >
              Edit Your Request
            </Button>
          ) : null}
          {existingStatus === "Denied" && !newRequest ? (
            <Button
              onClick={submitNewRequestHandler}
              style={{
                display: "inline-block",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            >
              Submit a new Request
            </Button>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default CashRequestComponent;
