import React from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Button } from "antd";
const { Content } = Layout;

function Disclaimer() {
  return (
    <Content className="content-sub">
      <Row style={{ margin: "8px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Link to={"/"}>Login / Dashboard</Link>
          <h2>Before you accept</h2>
        </Col>
      </Row>
      <Row className="xyz content-card">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p>
            Please read completely before you sign all the required fields and
            accept. If you have any questions, press HELP on the top right of
            your screen.
          </p>
          <Button>READ OFFER DOCUMENT</Button>
          <br />
          <Link to="/contact">
            Don't have an offer and would like to discuss one?
          </Link>
          <br />
          <h3>Why you should choose GLS</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
            repellat tempora consequatur earum nihil eligendi explicabo
            reprehenderit perferendis adipisci? Adipisci rerum quisquam unde
            expedita neque veritatis possimus voluptatem, repellendus aperiam?
          </p>
          <Link to="/how-it-works">How it works</Link>
          <br />
          <Link to="/">Our Unique Digital Dashboard</Link>
          <br />
          <Link to="/">Want our customers have to say</Link>
        </Col>
      </Row>
    </Content>
  );
}

export default Disclaimer;
