import React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";

const AcceptingOfferModal = (props) => {
  const pendingOfferAccepting = useSelector(
    (state) => state.offers.pendingOfferAccepting
  );
  let applyBtn = (
    <Button
      className="accept-btn"
      onClick={props.clickHandler.bind(this, "accept")}
    >
      ACCEPT
    </Button>
  );
  if (pendingOfferAccepting) {
    applyBtn = (
      <Spinner
        style={{
          width: "20px",
          height: "20px",
          fontSize: "20px",
          color: "#bfbfbf",
          margin: "0px",
          marginRight: "10px",
        }}
      />
    );
  }

  return (
    <div
      style={
        {
          // margin: "50px 20px",
        }
      }
    >
      <div
        style={{
          fontSize: "22px",
          fontWeight: "550",
          textDecoration: "underline",
          color: "black",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        Are you sure you want to accept this offer?
      </div>
      {props.numberOfPendingOffers > 1 ? (
        <div
          style={{
            fontSize: "18px",
            fontWeight: "400",
            color: "black",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          By accepting this offer, other offers will be declined.
        </div>
      ) : null}
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {applyBtn}
        <Button className="decline-btn" onClick={props.toggleModal}>
          GO BACK
        </Button>
      </div>
    </div>
  );
};

export default AcceptingOfferModal;
