import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import DashboardCardHOC from "../../../components/dashboard/DashboardCardHOC";
import DashboardHeader from "../../../components/dashboard/DashboardHeader";
import { resetPendingOfferAccepted } from "../../../store/actions/offers";
import PendingDeal from "./Deal/PendingDeal";

const PendingDeals = (props) => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = React.useState(false);

  const pendingOffers = useSelector((state) =>
    state.offers.offers.filter((offer) => offer.Status__c === "Offer Out")
  );
  const redirectTo = useSelector((state) => state.offers.redirectTo);

  if (redirectTo) {
    dispatch(resetPendingOfferAccepted());
    setRedirect(redirectTo);
  }

  const redirectIfOfferIsAccepted = () => {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: `${redirect}`,
            state: { noFetchOffer: true },
          }}
        />
      );
    }
  };

  let returning = (
    <>
      <DashboardHeader title="Deals" route="/deals" subtitle="Pending" />
    </>
  );

  if (pendingOffers.length !== 0) {
    const groupedPendingOffers = pendingOffers.reduce((rows, key, index) => {
      return (
        (index % 2 === 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows
      );
    }, []);
    returning = (
      <>
        <DashboardHeader title="Deals" route="/deals" subtitle="Pending" />
        <div key={pendingOffers.length} className="page-content">
          {groupedPendingOffers.map((pendingOffersRow, index) => {
            return (
              <div key={index} className="flex-column">
                <div className="pending-offers flex-row">
                  <PendingDeal
                    setRedirect={setRedirect}
                    offer={pendingOffersRow[0]}
                    numberOfPendingOffers={pendingOffers.length}
                  />
                  {pendingOffersRow.length === 2 ? (
                    <PendingDeal
                      setRedirect={setRedirect}
                      offer={pendingOffersRow[1]}
                      numberOfPendingOffers={pendingOffers.length}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
  return (
    <>
      {redirectIfOfferIsAccepted()}
      {returning}
    </>
  );
};

export default DashboardCardHOC(PendingDeals);
