import React from "react";
import MenuCardHOC from "../../components/menu/MenuCardHOC";

function WhyChooseUs() {
  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <span
          style={{ textDecoration: "underline" }}
          className="dashboard-title"
        >
          Why Choose Us
        </span>
      </div>

      <div
        style={{ marginTop: "5px", display: "flex", justifyContent: "center" }}
      >
        <h2>A Company You Can Trust to Get You More</h2>
      </div>

      <div style={{ marginTop: "30px", fontSize: "16px" }}>
        <p>
          For years, multi-million dollar marketing campaigns have conditioned
          settlement and annuity owners to think bigger is always better. They
          have misled potential customers to believe that the largest and most
          popular purchasing companies offer the most money and best services.
          In fact, some of the most well-known purchasing companies are owned by
          the same parent corporation, creating a false appearance of market
          competition.
        </p>
        <p>
          These companies - some of whose jingles you may recognize - can often
          be predatorial. They have prioritized the profits in their pockets
          instead of their customers', sometimes purchasing future payments for
          just pennies on the dollar to make up for their big-budget marketing
          costs, regardless of the sellers’ potential financial hardship.
        </p>
        <p>
          Gold Leaf is more than just catchy jingles and funny TV ads. At Gold
          Leaf, we conduct our business differently. We prioritize our
          customers’ satisfaction over our profits by spending less, so you get
          more.
        </p>
        <p>
          Our smaller marketing budget and new online platform minimize
          transaction costs, meaning you receive the highest valuation for your
          future payments right off the bat. Our first offer is the highest
          offer. We let our numbers speak for themselves. Avoid the dreaded
          “seller’s remorse” by working with us, a company operating with trust
          at the forefront.
        </p>
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "15px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h2>Real Support from Real People</h2>
      </div>
      <div style={{ fontSize: "16px", marginBottom: "50px" }}>
        <p>
          Gold Leaf has an extensive network of attorneys nationwide, allowing
          us to perform transactions in every state. Our funding specialists
          formulate customized solutions for your specific needs and ensure a
          smooth transaction process by emphasizing clear communication with
          your insurance provider.
        </p>
        <p>
          We have your best interest at heart and are committed to providing you
          with a low-pressure environment and close, one-on-one attention.
          Regardless of how complicated your circumstances are, Gold Leaf can
          help you reach financial freedom, so you can achieve your goals.
        </p>
        <p>
          If you want to sell an annuity or structured settlement and have the
          financial information available, we can assess your situation and
          provide a free, no-obligation quote today.
        </p>
      </div>
    </div>
  );
}

export default MenuCardHOC(WhyChooseUs);
