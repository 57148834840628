import React from "react";

const PrivacyPolicy = () => {
  const bold = { fontWeight: "bold" };
  return (
    <div
      style={{
        marginTop: "30px",
        marginLeft: "20px",
        marginRight: "20px",
        marginBottom: "50px",
      }}
    >
      <h1 style={{ marginLeft: "50px", fontSize: "26px" }}>PRIVACY NOTICE</h1>
      <h5 style={{ marginLeft: "50px" }}>Last updated March 07, 2022</h5>
      <p>
        This privacy notice for Gold Leaf Settlement Solutions LLC (doing
        business as Gold Leaf or Gold Leaf Settlement Solutions) ("
        <span style={{ ...bold }}>
          Gold Leaf or Gold Leaf Settlement Solutions
        </span>
        ," "<span style={{ ...bold }}>we</span>," "
        <span style={{ ...bold }}>us</span>," or "
        <span style={{ ...bold }}>our</span>"), describes how and why we might
        collect, store, use, and/or share ("process") your information when you
        use our services ("Services"), such as when you:
        <br />
        <div style={{ marginLeft: "50px" }}>
          {">"}Visit our website at Glsfunding.com, or any website of ours that
          links to this privacy notice
        </div>
        <div style={{ marginLeft: "50px" }}>
          {">"}Engage with us in other related ways ― including any sales,
          marketing, or events
        </div>
        <span style={{ ...bold }}>Questions or concerns?</span> Reading this
        privacy notice will help you understand your privacy rights and choices.
        If you do not agree with our policies and practices, please do not use
        our Services. If you still have any questions or concerns, please
        contact us at Info@glsfunding.com.
      </p>
      <p>
        <span style={{ ...bold, marginLeft: "15px" }}>
          SUMMARY OF KEY POINTS
        </span>
        <p style={{ ...bold }}>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click here to go
          directly to our table of contents.
        </p>
      </p>
      <p>
        <span style={{ ...bold }}>
          What personal information do we process?
        </span>{" "}
        When you visit, use, or navigate our Services, we may process personal
        information depending on how you interact with Gold Leaf or Gold Leaf
        Settlement Solutions and the Services, the choices you make, and the
        products and features you use. Click here to learn more.
      </p>
      <p>
        <span style={{ ...bold }}>
          Do we process any sensitive personal information?
        </span>{" "}
        We may process sensitive personal information when necessary with your
        consent or as otherwise permitted by applicable law.{" "}
        {/*Click here to learn
        more. */}
      </p>
      <p>
        <span style={{ ...bold }}>
          Do you receive any information from third parties?
        </span>{" "}
        We may receive information from public databases, marketing partners,
        social media platforms, and other outside sources.
      </p>
      <p>
        <span style={{ ...bold }}>How do you process my information?</span> We
        process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent. We process your information only when we
        have a valid legal reason to do so.
      </p>
      <p>
        <span style={{ ...bold }}>
          In what situations and with which types of parties do we share
          personal information?
        </span>{" "}
        We may share information in specific situations and with specific
        categories of third parties.
      </p>
      <p>
        <span style={{ ...bold }}>How do we keep your information safe?</span>{" "}
        We have organizational and technical processes and procedures in place
        to protect your personal information. However, no electronic
        transmission over the internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that
        hackers, cybercriminals, or other unauthorized third parties will not be
        able to defeat our security and improperly collect, access, steal, or
        modify your information.
      </p>
      <p>
        <span style={{ ...bold }}>What are your rights?</span> Depending on
        where you are located geographically, the applicable privacy law may
        mean you have certain rights regarding your personal information.
      </p>
      <p>
        <span style={{ ...bold }}>How do I exercise my rights?</span> The
        easiest way to exercise your rights is by filling out our data subject
        request form available here, or by contacting us. We will consider and
        act upon any request in accordance with applicable data protection laws.
      </p>
      <p>
        <span style={{ ...bold }}>TABLE OF CONTENTS</span>
      </p>
      <p>1. WHAT INFORMATION DO WE COLLECT?</p>
      <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
      <p>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
      <p>4. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
      <p>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
      <p>6. DO WE COLLECT INFORMATION FROM MINORS?</p>
      <p>7. WHAT ARE YOUR PRIVACY RIGHTS?</p>
      <p>8. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
      <p>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
      <p>10. DO WE MAKE UPDATES TO THIS NOTICE?</p>
      <p>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
      <p>
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </p>
      <p>
        <span style={{ ...bold }}>1. WHAT INFORMATION DO WE COLLECT?</span>
      </p>
      <p>
        <span style={{ ...bold }}>Personal information you disclose to us</span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> We collect personal
        information that you provide to us.
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <p>
        <span style={{ ...bold }}>Personal Information Provided by You.</span>{" "}
        The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the
        products and features you use. The personal information we collect may
        include the following:
        <br />
        {">"}names
        <br />
        {">"}phone numbers
        <br />
        {">"}email addresses
        <br />
        {">"}mailing addresses
        <br />
        {">"}job titles
        <br />
        {">"}usernames
        <br />
        {">"}passwords
        <br />
        {">"}contact preferences
        <br />
        {">"}contact or authentication data
        <br />
      </p>
      <p>
        <span style={{ ...bold }}>Sensitive Information. </span>When necessary,
        with your consent or as otherwise permitted by applicable law, we
        process the following categories of sensitive information:
        <br />
        {">"}health data
        <br />
        {">"}financial data
        <br />
        {">"}credit worthiness data
        <br />
        {">"}social security numbers or other government identifiers
        <br />
        {">"}information revealing race or ethnic origin
        <br />
      </p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <p>
        <span style={{ ...bold }}>Information automatically collected</span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> Some information — such as
        your Internet Protocol (IP) address and/or browser and device
        characteristics — is collected automatically when you visit our
        Services.
      </p>
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <p>The information we collect includes:</p>
      <div style={{ marginLeft: "20px" }}>
        <p>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect
          when you access or use our Services and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type, and settings and
          information about your activity in the Services (such as the date/time
          stamps associated with your usage, pages and files viewed, searches,
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called "crash dumps"), and hardware settings).
        </p>
        <p>
          Device Data. We collect device data such as information about your
          computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
        </p>
        <p>
          Location Data. We collect location data such as information about your
          device's location, which can be either precise or imprecise. How much
          information we collect depends on the type and settings of the device
          you use to access the Services. For example, we may use GPS and other
          technologies to collect geolocation data that tells us your current
          location (based on your IP address). You can opt out of allowing us to
          collect this information either by refusing access to the information
          or by disabling your Location setting on your device. However, if you
          choose to opt out, you may not be able to use certain aspects of the
          Services.
        </p>
      </div>
      <p>
        <span style={{ ...bold }}>
          Information collected from other sources
        </span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short: </span>We may collect limited data
        from public databases, marketing partners, and other outside sources.
      </p>
      <p>
        In order to enhance our ability to provide relevant marketing, offers,
        and services to you and update our records, we may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, affiliate programs, data providers, and from other third
        parties. This information includes mailing addresses, job titles, email
        addresses, phone numbers, intent data (or user behavior data), Internet
        Protocol (IP) addresses, social media profiles, social media URLs, and
        custom profiles, for purposes of targeted advertising and event
        promotion.
      </p>
      <p>
        <span style={{ ...bold }}>2. HOW DO WE PROCESS YOUR INFORMATION?</span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short: </span>We process your information
        to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent.
      </p>
      <p style={{ ...bold }}>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>
      <p>
        <span style={{ ...bold }}>
          To facilitate account creation and authentication and otherwise manage
          user accounts.
        </span>{" "}
        We may process your information so you can create and log into your
        account, as well as keep your account in working order.
      </p>
      <p>
        <span style={{ ...bold }}>
          To deliver and facilitate delivery of services to the user.{" "}
        </span>
        We may process your information to provide you with the requested
        service.
      </p>
      <p>
        <span style={{ ...bold }}>
          To respond to user inquiries/offer support to users.
        </span>
        We may process your information to respond to your inquiries and solve
        any potential issues you might have with the requested service.
      </p>
      <p>
        <span style={{ ...bold }}>
          To send administrative information to you.
        </span>{" "}
        We may process your information to send you details about our products
        and services, changes to our terms and policies, and other similar
        information.
      </p>
      <p>
        <span style={{ ...bold }}>To request feedback.</span> We may process
        your information when necessary to request feedback and to contact you
        about your use of our Services.
      </p>
      <p>
        <span style={{ ...bold }}>
          To send you marketing and promotional communications.{" "}
        </span>
        We may process the personal information you send to us for our marketing
        purposes, if this is in accordance with your marketing preferences. You
        can opt out of our marketing emails at any time. For more information,
        see "WHAT ARE YOUR PRIVACY RIGHTS?" below{")"}.
      </p>
      <p>
        <span style={{ ...bold }}>To deliver targeted advertising to you.</span>{" "}
        We may process your information to develop and display personalized
        content and advertising tailored to your interests, location, and more.
      </p>
      <p>
        <span style={{ ...bold }}>To post testimonials.</span> We post
        testimonials on our Services that may contain personal information.
      </p>
      <p>
        <span style={{ ...bold }}>To protect our Services.</span> We may process
        your information as part of our efforts to keep our Services safe and
        secure, including fraud monitoring and prevention.
      </p>
      <p>
        <span style={{ ...bold }}>
          To evaluate and improve our Services, products, marketing, and your
          experience.{" "}
        </span>{" "}
        We may process your information when we believe it is necessary to
        identify usage trends, determine the effectiveness of our promotional
        campaigns, and to evaluate and improve our Services, products,
        marketing, and your experience.
      </p>
      <p>
        <span style={{ ...bold }}>
          To determine the effectiveness of our marketing and promotional
          campaigns.
        </span>{" "}
        We may process your information to better understand how to provide
        marketing and promotional campaigns that are most relevant to you.
      </p>
      <p>
        <span style={{ ...bold }}>To comply with our legal obligations.</span>{" "}
        We may process your information to comply with our legal obligations,
        respond to legal requests, and exercise, establish, or defend our legal
        rights.
      </p>
      <p>
        <span style={{ ...bold }}>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short: </span>We may share information in
        specific situations described in this section and/or with the following
        categories of third parties.
      </p>
      <p>
        <span style={{ ...bold }}>
          Vendors, Consultants, and Other Third-Party Service Providers.
        </span>{" "}
        We may share your data with third-party vendors, service providers,
        contractors, or agents (“<span style={{ ...bold }}>third parties</span>
        ”) who perform services for us or on our behalf and require access to
        such information to do that work. The categories of third parties we may
        share personal information with are as follows:
        <br />
        <div style={{ marginLeft: "20px" }}>
          {">"}Affiliate Marketing Programs
          <br />
          {">"}Finance {"&"} Accounting Tools
          <br />
          {">"}Sales {"&"} Marketing Tools
          <br />
          {">"}Insurance Companies
          <br />
          {">"}Payment Processors
        </div>
      </p>
      <p>
        We also may need to share your personal information in the following
        situations:
      </p>
      <p>
        <span style={{ ...bold }}>Business Transfers.</span> We may share or
        transfer your information in connection with, or during negotiations of,
        any merger, sale of company assets, financing, or acquisition of all or
        a portion of our business to another company.
      </p>
      <p>
        <span style={{ ...bold }}>Affiliates. </span>We may share your
        information with our affiliates, in which case we will require those
        affiliates to honor this privacy notice. Affiliates include our parent
        company and any subsidiaries, joint venture partners, or other companies
        that we control or that are under common control with us.
      </p>
      <p>
        <span style={{ ...bold }}>Business Partners.</span> We may share your
        information with our business partners to offer you certain products,
        services, or promotions.
      </p>
      <p>
        <span style={{ ...bold }}>
          4. HOW LONG DO WE KEEP YOUR INFORMATION?
        </span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short: </span> We keep your information for
        as long as necessary to fulfill the purposes outlined in this privacy
        notice unless otherwise required by law.
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      <p>
        <span style={{ ...bold }}>
          5. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> We aim to protect your
        personal information through a system of organizational and technical
        security measures.
      </p>
      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
      <p>
        <span style={{ ...bold }}>
          6. DO WE COLLECT INFORMATION FROM MINORS?
        </span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> We do not knowingly collect
        data from or market to children under 18 years of age.
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        Info@glsfunding.com.
      </p>
      <p>
        <span style={{ ...bold }}>7. WHAT ARE YOUR PRIVACY RIGHTS?</span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> You may review, change, or
        terminate your account at any time.
      </p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:
        <a
          href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"
          target="_blank"
          rel="noreferrer"
        >
          https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html
        </a>
        .
      </p>
      <p>
        If you are located in Switzerland, the contact details for the data
        protection authorities are available here:{" "}
        <a
          href="https://www.edoeb.admin.ch/edoeb/en/home.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.edoeb.admin.ch/edoeb/en/home.html
        </a>
        .
      </p>
      <p>
        <span style={{ ...bold }}>Withdrawing your consent:</span> If we are
        relying on your consent to process your personal information, which may
        be express and/or implied consent depending on the applicable law, you
        have the right to withdraw your consent at any time. You can withdraw
        your consent at any time by contacting us by using the contact details
        provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        below.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal, nor when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <p>
        <span style={{ ...bold }}>
          Opting out of marketing and promotional communications:
        </span>{" "}
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        replying “STOP” or “UNSUBSCRIBE” to the SMS messages that we send, or by
        contacting us using the details provided in the section "HOW CAN YOU
        CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the
        marketing lists — however, we may still communicate with you, for
        example to send you service-related messages that are necessary for the
        administration and use of your account, to respond to service requests,
        or for other non-marketing purposes.
      </p>
      <p>
        <span style={{ ...bold }}>Account Information</span>
      </p>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
        <br />
        <div style={{ marginLeft: "20px" }}>
          {">"}Log in to your account settings and update your user account.
          <br />
          {">"}Contact us using the contact information provided.
        </div>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.If you have
        questions or comments about your privacy rights, you may email us at
        info@glsfunding.com.
      </p>
      <p>
        <span style={{ ...bold }}>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
      </p>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <p>
        <span style={{ ...bold }}>
          9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> Yes, if you are a resident of
        California, you are granted specific rights regarding access to your
        personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>
      <p>
        <span style={{ ...bold }}>
          CCPA Privacy Notice
          <br />
        </span>
        The California Code of Regulations defines a "resident" as:
        <div style={{ marginLeft: "20px" }}>
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
          <br />
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
        </div>
      </p>
      <p>All other individuals are defined as "non-residents."</p>
      <p>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>
      <p>
        <span style={{ ...bold }}>
          What categories of personal information do we collect?
          <br />
        </span>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>
      <p>
        <span style={{ ...bold }}>
          A{")"}Personal information categories listed in the California
          Customer Records statute:
        </span>
        <div style={{ marginLeft: "20px" }}>
          Name, contact information, education, employment, employment history,
          and financial information
        </div>
      </p>
      <p>
        <span style={{ ...bold }}>B{")"}Commercial Information:</span>
        <div style={{ marginLeft: "20px" }}>
          Transaction Information, purchase history, financial details, and
          payment information
        </div>
      </p>
      <p>
        <span style={{ ...bold }}>
          C{")"}Professional or employment-related information:
        </span>
        <div style={{ marginLeft: "20px" }}>
          Business contact details in order to provide you our services at a
          business level or job title, work history
        </div>
      </p>
      <p>
        <span style={{ ...bold }}>
          D{")"}Inferences drawn from other personal information:
        </span>
        <div style={{ marginLeft: "20px" }}>
          Inferences drawn from any of the collected personal information listed
          above to create a profile or summary about, for example, an
          individual’s preferences and characteristics{" "}
        </div>
      </p>
      <p>
        We may also collect other personal information outside of these
        categories instances where you interact with us in person, online, or by
        phone or mail in the context of:
        <br />
        <div style={{ marginLeft: "20px" }}>
          {">"}Receiving help through our customer support channels;
          <br />
          {">"}Participation in customer surveys or contests; and
          <br />
          {">"}Facilitation in the delivery of our Services and to respond to
          your inquiries.
        </div>
      </p>
      <p>
        <span style={{ ...bold }}>
          How do we use and share your personal information?
        </span>
      </p>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p>
        You can opt out from the selling of your personal information by
        disabling cookies in Cookie Preference Settings and clicking on the Do
        Not Sell My Personal Information link on our homepage.
      </p>
      <p>
        You may contact us by email at Glsfunding.com, by calling toll-free at
        (844) 989-3171, by visiting Glsfunding.com/contact, or by referring to
        the contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>
      <p>
        <span style={{ ...bold }}>
          Will your information be shared with anyone else?
        </span>
      </p>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for- profit entity that processes the
        information on our behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        information.
      </p>
      <p>
        Gold Leaf Settlement Solutions LLC has disclosed the following
        categories of personal information to third parties for a business or
        commercial purpose in the preceding twelve (12) months:
      </p>
      <p style={{ marginLeft: "20px" }}>
        Category B. Personal information, as defined in the California Customer
        Records law, such as your name, contact information, education,
        employment, employment history, and financial information.
      </p>
      <p>
        The categories of third parties to whom we disclosed personal
        information for a business or commercial purpose can be found under
        "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".
      </p>
      <p>
        Gold Leaf Settlement Solutions LLC has sold the following categories of
        personal information to third parties in the preceding twelve (12)
        months:
      </p>
      <p style={{ marginLeft: "20px" }}>
        Category B. Personal information, as defined in the California Customer
        Records law, such as your name, contact information, education,
        employment, employment history, and financial information.
      </p>
      <p>
        The categories of third parties to whom we sold personal information
        are:
        <br />
        <div style={{ marginLeft: "20px" }}>Affiliate Marketing Programs</div>
      </p>
      <p>
        <span style={{ ...bold }}>
          Your rights with respect to your personal data
        </span>
      </p>
      <p style={{ textDecoration: "underline" }}>
        Right to request deletion of the data — Request to delete
      </p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p style={{ textDecoration: "underline" }}>
        Right to be informed — Request to know
      </p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <div style={{ marginLeft: "20px" }}>
        <p>{">"}whether we collect and use your personal information;</p>

        <p>{">"}the categories of personal information that we collect;</p>

        <p>
          {">"}the purposes for which the collected personal information is
          used;
        </p>

        <p>{">"}whether we sell your personal information to third parties;</p>

        <p>
          {">"}the categories of personal information that we sold or disclosed
          for a business purpose;
        </p>

        <p>
          {">"}the categories of third parties to whom the personal information
          was sold or disclosed for a business purpose; and
        </p>

        <p>
          {">"}the business or commercial purpose for collecting or selling
          personal information.
        </p>
      </div>
      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <p style={{ textDecoration: "underline" }}>
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <p style={{ textDecoration: "underline" }}>Verification process</p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>
      <p style={{ textDecoration: "underline" }}>Other privacy rights</p>
      <p style={{ marginLeft: "20px" }}>
        {">"}You may object to the processing of your personal information.
      </p>
      <p style={{ marginLeft: "20px" }}>
        {">"}You may request correction of your personal data if it is incorrect
        or no longer relevant, or ask to restrict the processing of the
        information.
      </p>
      <p style={{ marginLeft: "20px" }}>
        {">"}You can designate an authorized agent to make a request under the
        CCPA on your behalf. We may deny a request from an authorized agent that
        does not submit proof that they have been validly authorized to act on
        your behalf in accordance with the CCPA.
      </p>
      <p>
        To exercise these rights, you can contact us by email at Glsfunding.com,
        by calling toll-free at 8449893171, by visiting Glsfunding.com/contact,
        or by referring to the contact details at the bottom of this document.
        If you have a complaint about how we handle your data, we would like to
        hear from you.
      </p>
      <p>
        <span style={{ ...bold }}>Financial Incentives</span>
      </p>
      <p>
        "Financial incentive" means a program, benefit, or other offering,
        including payments to consumers as compensation, for the disclosure,
        deletion, or sale of personal information.
      </p>
      <p>
        The law permits financial incentives or a price or service difference if
        it is reasonably related to the value of the consumer’s data. A business
        must be able to explain how the financial incentive or price or service
        difference is reasonably related to the value of the consumer’s data.
        The explanation must include:
      </p>
      <p style={{ marginLeft: "20px" }}>
        {">"}a good-faith estimate of the value of the consumer’s data that
        forms the basis for offering the financial incentive or price or service
        difference; and
      </p>
      <p style={{ marginLeft: "20px" }}>
        {">"}a description of the method the business used to calculate the
        value of the consumer’s data.
      </p>
      <p>
        We may decide to offer a financial incentive (e.g., price or service
        difference) in exchange for the retention or sale of a consumer’s
        personal information.
      </p>
      <p>
        If we decide to offer a financial incentive, we will notify you of such
        financial incentive and explain the price difference, as well as
        material terms of the financial incentive or price of service
        difference, including the categories of personal information that are
        implicated by the financial incentive or price or service difference.
      </p>
      <p>
        If you choose to participate in the financial incentive you can withdraw
        from the financial incentive at any time by emailing us at
        Glsfunding.com, by calling us toll-free at 8449893171, by visiting
        Glsfunding.com/contact, or by referring to the contact details at the
        bottom of this document
      </p>
      <p>
        <span style={{ ...bold }}>10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
      </p>
      <p>
        <span style={{ ...bold }}>In Short:</span> Yes, we will update this
        notice as necessary to stay compliant with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
      <p>
        <span style={{ ...bold }}>
          11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </span>
      </p>
      <p>
        If you have questions or comments about this notice, you may email us at
        <br />
        Info@glsfunding.com or by post to:
        <br />
        Gold Leaf Settlement Solutions LLC
        <br />
        18205 Biscayne Blvd
        <br />
        Suite 2218
        <br />
        Aventura, FL 33160
        <br />
        United States
      </p>
      <p>
        <span style={{ ...bold }}>
          12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </span>
      </p>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please submit a
        request form on the login page.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
