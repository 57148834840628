import React from "react";
import { Button } from "antd";

import DashboardCardHOC from "../../components/dashboard/DashboardCardHOC";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getNotifications,
  notificationRead,
} from "../../store/actions/notifications";
import Spinner from "../../components/UI/Spinner";
import Notification from "../../components/accounts/Notification";

const Notifications = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.notifications.loading);
  const token = useSelector((state) => state.auth.token);
  const [numberOfNotifications, setNumberOfNotifications] = React.useState(5);
  let returnData = (
    <Spinner
      style={{
        width: "50px",
        height: "50px",
        margin: "100px auto",
        fontSize: "50px",
        color: "#bfbfbf",
      }}
    />
  );

  React.useEffect(() => {
    dispatch(getNotifications(token));
  }, [dispatch, token]);

  const changeNumberOfNotifications = () => {
    setNumberOfNotifications((prevState) => prevState + 5);
  };

  const redirectHandler = (notification) => {
    history.push("/deals/" + notification.deal_id);
    if (!notification.read_datetime) {
      dispatch(notificationRead(token, notification.id));
    }
  };

  const offers = useSelector((state) =>
    state.offers.offers.concat(state.offers.deadOffers)
  );
  const allNotifications = useSelector(
    (state) => state.notifications.notifications
  );
  allNotifications.sort(function (a, b) {
    return new Date(b.creation_date) - new Date(a.creation_date);
  });
  const notificationsToShow = allNotifications.slice(0, numberOfNotifications);

  const table = notificationsToShow.map((notification) => {
    let name = "-";
    if (notification.deal_id && offers.length !== 0) {
      let offer = offers.find((el) => el.Id === notification.deal_id);
      if (offer) name = offer.Name;
    }
    return (
      <Notification
        key={notification.id}
        notification={notification}
        name={name}
        redirectHandler={redirectHandler}
      />
    );
  });

  if (!loading) {
    if (allNotifications.length === 0) {
      returnData = <>There are no notifications yet.</>;
    } else {
      returnData = (
        <>
          <div className="notification-table-header flex-row">
            <div className="notification-header-date">Date</div>
            <div className="notification-header-dealId">Deal</div>
            <div className="notification-header-message">Subject</div>
          </div>
          {table}
          <div className="notification-button">
            <Button
              className="button"
              style={{ width: "max-content" }}
              onClick={changeNumberOfNotifications}
            >
              Show More
            </Button>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <DashboardHeader title="Notifications" />
      <div className="page-content">{returnData}</div>
    </>
  );
};

export default DashboardCardHOC(Notifications);
